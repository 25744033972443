import gql from 'graphql-tag'

export const GET_GROUP = gql`
query getGroup($where: WhereGroupUniqueInput){
  getGroup(where: $where) {
    id
    name
    userIds
    users {
      id
      firstName
      lastName
      email
      password
      phone
      address
      cityId
      stateId
      countryId
      motherName
      fatherName
      dateOfBirth
      userName
      isActive
      role
      keyId
      kyc {
        id
        panCard
        aadharCard
        passportSize
        signature
        cancelledChequeSnap
        bankStatement
      }
      isAuto
      isAssociate
      fundReceived
      isKycDone
      completedSteps
      amcStartDate
      sipStartDate
      closedDate
      initialExposure
      clientCode
      strategyCode
      createdBy {
        id
      }
      updatedBy {
        id
      }
      token
      tokenExpiration
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
}
`

export const GET_GROUPS = gql`
query getGroups($where: WhereGroupInput, $skip: Int, $limit: Int, $sort: SortInput){
  getGroups(where: $where, skip: $skip, limit: $limit, sort: $sort) {
    groups{
      id
      name
      userIds
      users{
        id
        phone
        email
      }
      createdAt
    }
    count
  }
}
`

export const GET_GROUPS_NAME = gql`
query getGroups($where: WhereGroupInput, $skip: Int, $limit: Int, $sort: SortInput){
  getGroups(where: $where, skip: $skip, limit: $limit, sort: $sort) {
    groups{
      id
      name
      createdAt
    }
  }
}
`
