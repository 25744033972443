import React, { Fragment, useState, useContext } from 'react'
import { PageHeader, Table, Button, Input, Space, Tag, DatePicker } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/react-hooks'
import { NavLink } from 'react-router-dom'
import get from 'lodash/get'
import moment from 'moment'

import client from 'apollo'
import { AppContext } from 'AppContext'
import Page404 from 'components/Page404'
import CustomMeta from 'components/CustomMeta'
import { GET_TICKETS } from './graphql/Queries'
import openNotification from 'utils/Notification'
import { getUserNavLinkByObject } from 'utils/User'
import { CREATE_TICKET } from './graphql/Mutations'
import CreateTicketModal from './CreateTicketModal'

const { RangePicker } = DatePicker

export default function () {
  let tableData = []
  const [skip, setSkip] = useState(0)
  const [filters, setFilters] = useState({})
  const [currentPage, setCurrentPage] = useState(1)
  const { state: { isClient, isAdmin } } = useContext(AppContext)
  const [showFormModal, setShowFormModal] = useState(false)
  const [showFormLoading, setShowFormLoading] = useState(false)

  const variables = { where: filters, limit: 10, skip }
  const { data, loading, error } = useQuery(GET_TICKETS, { variables, fetchPolicy: 'network-only' })

  if (error) return <Page404 />

  let totalCount = 0
  if (!loading && get(data, 'getTickets.tickets')) {
    totalCount = data.getTickets.count
    tableData = data.getTickets.tickets.map((ticket, key) => ({ key: key.toString(), ...ticket }))
  }

  function handleCreateTicket(values, resetForm) {
    setShowFormLoading(true)
    if (get(values, 'fileUrl[0].originFileObj')) {
      values.fileUrl = get(values, 'fileUrl[0].originFileObj')
    } else {
      delete values.fileUrl
    }
    client.mutate({ mutation: CREATE_TICKET, variables: { ...values }, refetchQueries: [{ query: GET_TICKETS, variables, fetchPolicy: 'network-only' }] })
      .then((res) => {
        openNotification('success', 'Ticket Generated Successfully')
        setShowFormModal(false)
        resetForm()
      })
      .catch((err) => console.log(err))
      .finally(() => setShowFormLoading(false))
  }

  function handlePagination(page) {
    setSkip((page - 1) * 10)
    setCurrentPage(page)
  }

  function handleSearch(value, confirm, filedName) {
    value = typeof value === 'object' ? value[0] : value
    confirm()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    tempFilters[filedName] = value
    setFilters(tempFilters)
    setCurrentPage(1)
  }

  function handleReset(clearFilters, filedName) {
    clearFilters()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    if (tempFilters[filedName]) {
      delete tempFilters[filedName]
    }
    setFilters(tempFilters)
    setCurrentPage(1)
  }

  function handleTableChange(pagination, filter, sorter) {
    if (filter.status) {
      const tempFilters = JSON.parse(JSON.stringify(filters))
      tempFilters['status'] = filter.status
      setFilters(tempFilters)
      setCurrentPage(1)
    } else {
      const tempFilters = JSON.parse(JSON.stringify(filters))
      tempFilters['status'] = ''
      delete tempFilters.status
      setFilters(tempFilters)
      setCurrentPage(1)
    }
  }

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys[0], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters, dataIndex)} size='small' style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
  })

  const getColumnDateProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <RangePicker
          onChange={e => {
            const from = `from${dataIndex}Date`
            const to = `to${dataIndex}Date`
            let tempFilters
            if (e) {
              confirm()
              tempFilters = JSON.parse(JSON.stringify(filters))
              tempFilters[from] = e[0]
              tempFilters[to] = e[1]
            } else {
              clearFilters()
              tempFilters = JSON.parse(JSON.stringify(filters))
              delete tempFilters[from]
              delete tempFilters[to]
            }
            setFilters(tempFilters)
            setCurrentPage(1)
          }}
        />
      </div>
    ),
  })

  const columns = [
    {
      title: 'Ticket Number',
      dataIndex: 'ticketNumber',
      key: 'ticketNumber',
      render: (text, record) => <NavLink to={`/tickets/${record.id}`}>{text}</NavLink>,
      ...getColumnSearchProps('ticketNumber')
    },
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: status => <Tag className={status}>{status}</Tag>,
      filters: [
        { text: 'OPEN', value: 'OPEN' },
        { text: 'PENDING', value: 'PENDING' },
        { text: 'CLOSED', value: 'CLOSED' }
      ]
    },
    {
      title: 'Client',
      dataIndex: 'generatedBy',
      key: 'generatedBy',
      render: user => getUserNavLinkByObject(user, isClient),
    },
    {
      title: 'Assigned To',
      dataIndex: 'assignUser',
      key: 'assignUser',
      render: user => getUserNavLinkByObject(user, isClient),
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: date => moment(date).format('Do MMMM YYYY'),
      ...getColumnDateProps('CreatedAt')
    },
    {
      title: 'Closed At',
      dataIndex: 'closedDate',
      key: 'closedDate',
      render: date => date ? moment(date).format('Do MMMM YYYY') : '-',
      ...getColumnDateProps('Closed')
    },
  ]

  return (
    <Fragment>
      <CustomMeta title='Tickets' />
      <PageHeader
        className='box'
        title='Tickets'
        extra={[
          !isAdmin &&
          <Button
            key='1'
            type='primary'
            onClick={() => setShowFormModal(true)}
          >
            Generate Ticket
          </Button>
        ]}
      />
      {
        !isAdmin && showFormModal &&
        <CreateTicketModal
          visible={showFormModal}
          loading={showFormLoading}
          onCreate={handleCreateTicket}
          onCancel={() => setShowFormModal(false)}
        />
      }
      <Table
        loading={loading}
        columns={isClient ? columns.filter((column) => column.title !== 'Client') : columns}
        scroll={{ x: true }}
        dataSource={tableData}
        onChange={handleTableChange}
        pagination={{ pageSize: 10, total: totalCount, onChange: handlePagination, current: currentPage }}
      />
    </Fragment>
  )
}
