import React from 'react'
import { Table } from 'antd'

const columns = [
  {
    title: 'Trade EQ Report',
    className: 'report-title main-title',
    children: [
      {
        title: 'Entry Date',
        dataIndex: 'entrydate',
        key: 'entrydate',
        className: 'report-text'
      },
      {
        title: 'Company',
        dataIndex: 'company',
        key: 'company',
        className: 'report-text'
      },
      {
        title: 'script',
        dataIndex: 'script',
        key: 'script',
        className: 'report-text'
      },
      {
        title: 'Qty',
        dataIndex: 'qty',
        key: 'qty',
        className: 'report-numeric'
      },
      {
        title: 'Rate',
        dataIndex: 'rate',
        key: 'rate',
        className: 'report-numeric'
      },
      {
        title: 'Strategy Code',
        dataIndex: 'StrategyCode',
        key: 'StrategyCode',
        className: 'report-numeric'
      },
      {
        title: 'Client Code',
        dataIndex: 'ClientCode',
        key: 'ClientCode',
        className: 'report-text'
      },
      {
        title: 'Member Code',
        dataIndex: 'Membercode',
        key: 'Membercode',
        className: 'report-text'
      }
    ]
  }
]

export default function ({ reportData }) {
  const data = reportData.map((report, key) => { return { key, ...report } })

  return (
    <Table
      dataSource={data}
      columns={columns}
      className='nlv-tracker-report'
      pagination={{ pageSize: 5000, hideOnSinglePage: true }}
      scroll={{ x: true, y: 'calc(100vh - 72px - 24px - 24px - 24px - 40px - 65px)' }}
    />
  )
}
