import React, { useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Select, PageHeader, Form, Row, Col, Input, Button } from 'antd'
import map from 'lodash/map'
import get from 'lodash/get'

import client from 'apollo'
import Page404 from 'components/Page404'
import { GET_MESSAGES } from './graphql/Queries'
import openNotification from 'utils/Notification'
import { UPDATE_MESSAGE } from './graphql/Mutations'

const { Option } = Select

const formItemLayout = {
  labelCol: { xs: { span: 24 }, sm: { span: 24 } },
  wrapperCol: { xs: { span: 24 }, sm: { span: 24 } }
}

const tailFormItemLayout = {
  wrapperCol: { xs: { span: 24, offset: 0 }, sm: { span: 16, offset: 8 } }
}

export default function () {
  const [form] = Form.useForm()
  const [selected, setSelected] = useState(undefined)
  const [formLoading, setFormLoading] = useState(false)
  const { data, loading, error } = useQuery(GET_MESSAGES, { fetchPolicy: 'network-only' })

  let messages
  if (error) return <Page404 />
  if (!loading && get(data, 'getAllMessages')) {
    messages = data.getAllMessages.map((msg, key) => ({ key: key.toString(), ...msg }))
  }

  function handleChange(value) {
    setSelected(messages.filter((message) => message.type === value)[0])
    form.setFieldsValue(messages.filter((message) => message.type === value)[0])
  }

  const onFinish = values => {
    const where = { id: parseInt(selected.id), type: selected.type }
    setFormLoading(true)
    client
      .mutate({
        mutation: UPDATE_MESSAGE,
        variables: { data: values, where },
        refetchQueries: [{ query: GET_MESSAGES, fetchPolicy: 'network-only' }]
      })
      .then((res) => {
        openNotification('success', 'Message Updated Successfully')
      })
      .catch((err) => console.log(err))
      .finally(() => setFormLoading(false))
  }

  return (
    <>
      <PageHeader className='box' title='Messages' />
      <Select
        style={{ minWidth: 250 }}
        onChange={handleChange}
        placeholder='Select Message'
      >
        {
          map(messages, (message, key) => {
            const { title, type } = message
            return <Option value={type} key={key}>{title}</Option>
          })
        }
      </Select>
      {
        selected &&
        <Form
          {...formItemLayout}
          form={form}
          name='message'
          onFinish={onFinish}
          className='user-form'
          scrollToFirstError
        >
          <Row gutter={16}>
            <Col className='gutter-row' span={24}>
              <Form.Item
                name='title'
                label='Title'
                rules={[{ required: true, message: 'Please input your Title!' }]}
              >
                <Input placeholder='Enter Message Title' />
              </Form.Item>
            </Col>
            <Col className='gutter-row' span={24}>
              <Form.Item
                name='description'
                label='description'
                rules={[{ required: true, message: 'Please input your Description!' }]}
              >
                <Input.TextArea rows={5} placeholder='Enter Description' />
              </Form.Item>
            </Col>
            <Col className='gutter-row' span={24}>
              <Form.Item {...tailFormItemLayout}>
                <Button type='primary' htmlType='submit' loading={formLoading}>Save</Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      }
    </>
  )
}
