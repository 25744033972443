import React, { Fragment, useState } from 'react'
import { MenuOutlined, EditOutlined } from '@ant-design/icons'
import { PageHeader, Button, Menu, Dropdown } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import get from 'lodash/get'

import About from 'components/About'
import Page404 from 'components/Page404'
import { GET_USER } from './graphql/Queries'
import Loader from 'components/loaders/Loader'
import CustomMeta from 'components/CustomMeta'
import { getName, staffUsersData } from 'utils/User'

export default function (props) {
  let userDetails = {}
  const [visibleMenu, setVisibleMenu] = useState(false)
  const { history, match: { params: { userId } } } = props
  const { data, loading, error } = useQuery(GET_USER, { variables: { id: userId }, fetchPolicy: 'network-only' })

  if (loading) return <Loader />
  if (error) return <Page404 />

  if (!loading && get(data, 'getUser')) {
    userDetails = data.getUser
  }

  function handleMenuClick(e) {
    console.log('click', e)
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key='1' onClick={() => history.push(`/users/${userId}/edit`)}>
        <EditOutlined /> Edit
      </Menu.Item>
    </Menu>
  )

  return (
    <Fragment>
      <CustomMeta title={getName(userDetails)} description='Users' />
      <PageHeader
        className='box'
        onBack={() => history.goBack()}
        title={getName(userDetails)}
        extra={[
          window.innerWidth >= 991 ?
            <Fragment key={0}>
              <Button key={2} type='primary' onClick={() => history.push(`/users/${userId}/edit`)}>Edit</Button>
            </Fragment>
            :
            <Dropdown onVisibleChange={() => setVisibleMenu(false)} key={0} overlay={menu} onClick={() => setVisibleMenu(!visibleMenu)} visible={visibleMenu}>
              <Button key={1}><MenuOutlined /></Button>
            </Dropdown>
        ]}
      />
      <About details={staffUsersData(userDetails)} userId={userDetails.id} />
    </Fragment>
  )
}
