import React from 'react'
import { Table } from 'antd'
import sumBy from 'lodash/sumBy'

const columns = [
  {
    title: 'Ledger',
    className: 'report-title main-title',
    children: [
      {
        title: 'Entry Date',
        dataIndex: 'Entrydate',
        key: 'Entrydate',
        className: 'report-text'
      },
      {
        title: 'Debit Amount',
        dataIndex: 'DR_Amount',
        key: 'DR_Amount',
        className: 'report-numeric'
      },
      {
        title: 'Credit Amount',
        dataIndex: 'CR_Amount',
        key: 'CR_Amount',
        className: 'report-numeric'
      },
      {
        title: 'Remark',
        dataIndex: 'remark',
        key: 'remark',
        className: 'report-text'
      }
    ]
  }
]

export default function ({ reportData }) {
  let data = reportData.map((report, key) => { return { key, ...report } })
  data = [
    ...data,
    {
      Entrydate: 'Total',
      DR_Amount: sumBy(data, 'DR_Amount'),
      CR_Amount: sumBy(data, 'CR_Amount'),
      remark: '',
    }
  ]
  return (
    <Table
      className='ledger-ilts-report'
      dataSource={data}
      columns={columns}
      // scroll={{ x: true, y: 240 }}
      pagination={{ pageSize: 1000, hideOnSinglePage: true }}
    />
  )
}
