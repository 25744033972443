import React from 'react'
import { get } from 'lodash'
import { Button } from 'antd'
import { useQuery } from '@apollo/react-hooks'

import NoData from 'components/NoData'
import Page404 from 'components/Page404'
import { exportGraphAsPdf } from 'utils/Graph'
import Loader from 'components/loaders/Loader'
import CurrentPositionTable from './CurrentPositionTable'
import { GET_CURRENT_POSITION_REPORT } from '../graphql/Queries'
import '../index.css'

export default function () {
  const { data, loading, error } = useQuery(GET_CURRENT_POSITION_REPORT)

  if (loading) return <Loader />
  if (error) return <Page404 />
  if (!get(data, 'spCurrentPosition')) return <NoData />

  const { spCurrentPosition: [spCurrentPosition] } = data

  function generatePdf() {
    exportGraphAsPdf('current-position.pdf', 'CURRENT-REPORT-PDF')
  }

  return (
    <>
      <Button className='download-button' key='2' type='primary' size='small' onClick={() => generatePdf()}>Download</Button>
      <div className='report-table-wrapper' id='CURRENT-REPORT-PDF'>
        <CurrentPositionTable reportData={spCurrentPosition.filter((report, key) => (key !== 0 && !!report.script))} />
      </div>
    </>
  )
}
