import React, { useState, useContext } from 'react'
import { Button, DatePicker, Select } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import { get, map } from 'lodash'

import { GET_GROUPS_NAME } from 'modules/Groups/graphql/Queries'
import UserPerformance from './UserPerformance'
import { exportGraphAsPdf } from 'utils/Graph'
import { AppContext } from 'AppContext'
import '../index.css'

const { RangePicker } = DatePicker
const Option = Select.Option

export default function () {
  let groups = []
  const [where, setWhere] = useState({})
  const [groupId, setGroupId] = useState(undefined)
  const { state: { isClient } } = useContext(AppContext)

  const { data, loading } = useQuery(GET_GROUPS_NAME, { fetchPolicy: 'network-only' })
  if (!loading && get(data, 'getGroups.groups')) {
    groups = data.getGroups.groups.map((group, key) => (group))
  }

  function returnGroups() {
    return map(groups, (group) => {
      const { id, name } = group
      return <Option value={id}>{name}</Option>
    })
  }

  function generatePdf() {
    exportGraphAsPdf('TRADER-EQ-report.pdf', 'TRADER-EQ-REPORT-PDF')
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          marginBottom: '5px',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        {
          !isClient &&
          <>
            <Select className='gx-select-sm' defaultValue='' onChange={value => setGroupId(value ? parseInt(value) : undefined)} style={{ minWidth: 100 }}>
              <Option value=''>All Clients</Option>
              {returnGroups()}
            </Select>
            &nbsp;
          </>
        }
        <RangePicker
          onChange={e => e ? setWhere({ fromDate: e[0], toDate: e[1] }) : setWhere({})}
        />
          &nbsp;
        <Button style={{ marginBottom: 0, marginRight: 0, lineHeight: '1px' }} key='2' type='primary' size='small' onClick={() => generatePdf()}>Download</Button>
      </div>
      <UserPerformance where={where} groupId={groupId} />
    </>
  )
}
