import React from 'react'
import get from 'lodash/get'
import { Button } from 'antd'
import { useQuery } from '@apollo/react-hooks'

import NoData from 'components/NoData'
import Page404 from 'components/Page404'
import { exportGraphAsPdf } from 'utils/Graph'
import Loader from 'components/loaders/Loader'
import { GET_AVERAGE_INDEX_REPORT } from 'modules/Reports/graphql/Queries'
import AverageIndexTable from 'modules/Reports/AverageIndex/AverageIndexTable'
import 'modules/Reports/index.css'

export default function (props) {
  let { match: { params: { clientCode } } } = props
  const { data, loading, error } = useQuery(GET_AVERAGE_INDEX_REPORT, { variables: { where: { clientCode, date: null, strategy: 'ILTS' } } })

  function generatePdf() {
    exportGraphAsPdf('average-report.pdf', 'AVG-REPORT-PDF')
  }

  if (loading) return <Loader />
  if (error) return <Page404 />
  if (!get(data, 'spAverageIndexreport')) return <NoData />

  const { spAverageIndexreport: [spAverageIndexreport] } = data

  return (
    <>
      <Button className='download-button' key='2' type='primary' size='small' onClick={() => generatePdf()}>Download</Button>
      <div className='report-table-wrapper' id='AVG-REPORT-PDF'>
        <AverageIndexTable reportData={spAverageIndexreport} />
      </div>
    </>
  )
}
