import React from 'react'
import { Table } from 'antd'

const columns = [
  {
    title: 'Monthly Return Report',
    className: 'report-title main-title',
    children: [
      {
        title: 'Entry Date',
        dataIndex: 'EntryDate',
        key: 'EntryDate',
        className: 'report-text',
      },
      {
        title: 'Value',
        dataIndex: 'Value',
        key: 'Value',
        className: 'report-numeric',
      }
    ]
  }
]

export default function ({ reportData }) {
  const data = reportData.map((report, key) => { return { key, ...report } })

  return (
    <Table
      dataSource={data}
      columns={columns}
      className='nlv-tracker-report'
      pagination={{ pageSize: 5000, hideOnSinglePage: true }}
      scroll={{ x: true, y: 'calc(100vh - 72px - 24px - 24px - 36px - 35px - 35px)' }}
    />
  )
}
