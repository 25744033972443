import React, { Fragment, useState, useContext } from 'react'
import { Table, Tag, Tooltip, Button, Switch, Input, Space } from 'antd'
import { EditTwoTone, SearchOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/react-hooks'
import { NavLink } from 'react-router-dom'
import get from 'lodash/get'

import client from 'apollo'
import history from 'CustomHistory'
import { AppContext } from 'AppContext'
import Page404 from 'components/Page404'
import openNotification from 'utils/Notification'
import { UPDATE_USER } from '../graphql/Mutations'
import { GET_CLIENT_USERS } from '../graphql/Queries'
import { getFirstName, getLastName, userRoles } from 'utils/User'

export default function ({ userId }) {
  let tableData = []
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const { state } = useContext(AppContext)
  const [filters, setFilters] = useState({ createdById: userId })
  const [currentPage, setCurrentPage] = useState(1)
  const userFilters = userRoles[state.currentUser.role]
  const [switchLoading, setSwitchLoading] = useState('')
  const { data, loading, error } = useQuery(GET_CLIENT_USERS, { variables: { where: filters, limit, skip }, fetchPolicy: 'network-only' })

  if (error) return <Page404 />

  let totalCount = 0
  if (!loading && get(data, 'getClientUsers.users')) {
    totalCount = data.getClientUsers.count
    tableData = data.getClientUsers.users.map((user, key) => ({ key: key.toString(), ...user }))
  }

  function handleSwitch(record) {
    setSwitchLoading(record.id)
    const queryVariables = { id: record.id, data: { isActive: !record.isActive } }
    client.mutate({ mutation: UPDATE_USER, variables: queryVariables, refetchQueries: [{ query: GET_CLIENT_USERS, variables: { where: filters, limit, skip } }] })
      .then((res) => openNotification('success', 'User Updated Successfully'))
      .catch((err) => console.log(err))
      .finally(() => setSwitchLoading(''))
  }

  function renderAction(record) {
    const { id } = record
    return <div className='action-icons'>
      <Tooltip title='Edit'>
        <EditTwoTone onClick={() => history.push(`/clients/${id}/edit`)} />
      </Tooltip>
      <Switch
        size='small'
        checked={record.isActive}
        loading={switchLoading === id}
        onClick={() => handleSwitch(record)} defaultChecked={record.isActive}
        disabled={state.currentUser.email === record.email}
      />
    </div>
  }

  function handlePagination(page) {
    setSkip((page - 1) * limit)
    setCurrentPage(page)
  }

  function handlePageSizeChange(current, size) {
    setLimit(size)
  }

  function handleSearch(value, confirm, filedName) {
    value = typeof value === 'object' ? value[0] : value
    confirm()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    tempFilters[filedName] = value
    setFilters(tempFilters)
    setCurrentPage(1)
  }

  function handleReset(clearFilters, filedName) {
    clearFilters()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    tempFilters[filedName] = ''
    setFilters(tempFilters)
    setCurrentPage(1)
  }

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys[0], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters, dataIndex)} size='small' style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
  })

  function handleTableChange(pagination, filter, sorter) {
    if (filter.role) {
      const tempFilters = JSON.parse(JSON.stringify(filters))
      tempFilters['role'] = filter.role
      setFilters(tempFilters)
      setCurrentPage(1)
    } else {
      const tempFilters = JSON.parse(JSON.stringify(filters))
      tempFilters['role'] = ''
      delete tempFilters.role
      setFilters(tempFilters)
      setCurrentPage(1)
    }
  }

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      render: (text, record) => <NavLink to={`/clients/${record.id}`}>{getFirstName(record)}</NavLink>,
      ...getColumnSearchProps('firstName')
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      render: (text, record) => <NavLink to={`/clients/${record.id}`}>{getLastName(record)}</NavLink>,
      ...getColumnSearchProps('lastName')
    },
    {
      title: 'Username',
      dataIndex: 'userName',
      key: 'userName',
      ...getColumnSearchProps('userName')
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: text => <a href={`mailto:${text}`}>{text}</a>,
      ...getColumnSearchProps('email')
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      render: text => <a href={`tel:${text}`}>{text}</a>,
      ...getColumnSearchProps('phone')
    },
    {
      title: 'Role',
      key: 'role',
      dataIndex: 'role',
      render: role => <Tag className={role}>{role}</Tag>,
      filters: userFilters
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => renderAction(record),
    }
  ]

  return (
    <Fragment>
      <Table
        loading={loading}
        columns={columns}
        scroll={{ x: true }}
        dataSource={tableData}
        onChange={handleTableChange}
        pagination={{ pageSize: limit, total: totalCount, onChange: handlePagination, current: currentPage, onShowSizeChange: handlePageSizeChange }}
      />
    </Fragment>
  )
}
