import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Form, Input } from 'antd'

import CustomMeta from 'components/CustomMeta'
import { LOGIN } from './graphql/Mutations'
import Logo from 'assets/images/logo.png'
import { authLogin } from '.'
import client from 'apollo'
import './index.css'


const FormItem = Form.Item

export default function () {
  const [form] = Form.useForm()

  function handleLogin(values) {
    client.mutate({ mutation: LOGIN, variables: { data: values } })
      .then((res) => authLogin(res))
      .catch((err) => console.log(err))
  }

  return (
    <div className='gx-app-login-wrap'>
      <CustomMeta title='Login' />
      <div className='gx-app-login-container'>
        <div className='gx-app-login-main-content'>
          <div className='gx-app-logo-content'>
            <div className='gx-app-logo-content-bg'>
              {/* <img src='https://via.placeholder.com/272x395' alt='Nature' /> */}
            </div>
            <div className='gx-app-logo-wid'>
              <h1>Sign In</h1>
              <p>By Sign In, you can avail full features of our services.</p>
            </div>
            <div className='gx-app-logo'>
              <img alt='Finideas' src={Logo} />
            </div>
          </div>
          <div className='gx-app-login-content'>
            <Form form={form} onFinish={handleLogin} className='gx-signin-form gx-form-row0'>
              <FormItem name='email'
                rules={[
                  { required: true, message: 'Please enter your E-mail!' },
                  { message: 'The input is not valid E-mail!', pattern: new RegExp(/^[a-z0-9._-]+@[a-z0-9.-]+\.[a-z]{2,4}$/) }
                ]}
              >
                <Input placeholder='Email' />
              </FormItem>
              <FormItem name='password' rules={[{ required: true, message: 'Please enter your password!', min: 8, max: 16 }]}>
                <Input type='password' placeholder='Password' />
              </FormItem>
              <FormItem>
                <Button type='primary' className='gx-mb-0' htmlType='submit'>Sign In</Button>
              </FormItem>
              <span className='forgot-password'><Link to='/forgot-password'>Forgot Password?</Link></span>
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}
