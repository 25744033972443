import React, { Fragment, useState } from 'react'
import omit from 'lodash/omit'
import { PageHeader } from 'antd'

import client from 'apollo'
import UserForm from './components/UserForm'
import { CREATE_USER } from './graphql/Mutations'
import openNotification from 'utils/Notification'

export default function ({ history }) {
  const [loading, setLoading] = useState()

  const onFinish = values => {
    setLoading(true)
    const queryVariables = { data: omit(values, 'confirm') }

    client.mutate({ mutation: CREATE_USER, variables: queryVariables })
      .then((res) => {
        openNotification('success', 'User Created Successfully')
        history.replace(`/users/${res.data.createUser.id}`)
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false))
  }

  return (
    <Fragment>
      <PageHeader
        className='box'
        onBack={() => history.goBack()}
        title='Add New User'
      />
      <UserForm onFinish={onFinish} loading={loading} />
    </Fragment>
  )
}
