import gql from 'graphql-tag'

export const LOGIN = gql`
mutation login($data: LoginCreateInput!){
  login(data: $data) {
    token
  }
}
`

export const RESET_PASSWORD = gql`
mutation resetPassword($data: ResetPasswordInput){
  resetPassword(data: $data)
}
`
