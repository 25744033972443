import React from 'react'
// import { DownloadOutlined } from '@ant-design/icons'
import { Button, Col, Row } from 'antd'

import history from 'CustomHistory'
import Widget from 'components/Widget'
import LineIndicator from './LineIndicator'

const Portfolio = (props) => {
  const { type = 'SIP', Completed, CompletedPer, Pending, PendingPer } = props

  return (
    <Widget>
      <h2 className='h4 gx-mb-3'>{type} Summary</h2>
      <Row>
        <Col lg={12} md={12} sm={12} xs={24}>

          <div className='ant-row-flex'>
            <h2 className='gx-mr-2 gx-mb-0 gx-fs-xxxl gx-font-weight-medium'>₹{props[`Total${type}`] || 0}</h2>
            {/* <h4 className='gx-pt-2 gx-chart-up'>64% <i className='icon icon-menu-up gx-fs-sm' /></h4> */}
          </div>
          <p className='gx-text-grey'>Total Amount</p>
          <div className='ant-row-flex gx-mb-3 gx-mb-md-2'>
            <Button className='gx-mr-2' type='primary' size='small' onClick={() => history.push(`${type.toLowerCase()}`)}>View All</Button>
            {/* <Button className='gx-btn-cyan'>Withdraw</Button> */}
          </div>

          {/* <p className='gx-text-primary gx-pointer gx-d-none gx-d-sm-block gx-mb-1'>
            <DownloadOutlined /> Download
          </p> */}
        </Col>
        <Col lg={12} md={12} sm={12} xs={24}>
          <div className='gx-site-dash'>
            <h5 className='gx-mb-3'>Overview</h5>
            <ul className='gx-line-indicator gx-fs-sm gx-pb-1 gx-pb-sm-0'>
              <li>
                <LineIndicator width={`${CompletedPer || 0}%`} title='Paid' title2={`₹${Completed || 0}`} color='primary' value={CompletedPer ? `${CompletedPer}%` : `0%`} />
              </li>
              <li>
                <LineIndicator width={`${PendingPer || 0}%`} title='Pending' title2={`₹${Pending || 0}`} color='pink' value={PendingPer ? `${PendingPer}%` : `0%`} />
              </li>
              {/* <li>
                <LineIndicator width='24%' title='LTE' title2='0.71' color='orange' value='34%' />
              </li> */}
            </ul>
            <p className='gx-text-primary gx-pointer gx-d-block gx-d-sm-none gx-mb-0 gx-mt-3'>
              <i className='icon icon-add-circle gx-fs-lg gx-d-inline-flex gx-vertical-align-middle' /> Add New Wallet
            </p>
          </div>
        </Col>
      </Row>
    </Widget>
  )
}

export default Portfolio
