import React from 'react'
import moment from 'moment'
import { get } from 'lodash'
import { Modal, Form, Input, InputNumber, Select, DatePicker } from 'antd'

const { Option } = Select

const CollectionCreateForm = ({ visible, onCreate, onCancel, loading, isUpdate, onUpdate, amount, data, date }) => {
  date = date ? moment() : moment(date)
  if (get(data, 'whichYear')) {
    data.whichYear = moment(data.whichYear.toString())
  }

  if (get(data, 'client.id')) {
    data.clientId = get(data, 'client.id')
  }

  const [form] = Form.useForm()

  const whichYear = moment()
  const status = 'COMPLETED'

  function resetForm() {
    form.resetFields()
  }

  const paymentTypes = [
    { text: 'CASH', value: 'CASH' },
    { text: 'CHEQUE', value: 'CHEQUE' },
    { text: 'ONLINE', value: 'ONLINE' },
  ]

  const statusTypes = [
    { text: 'PENDING', value: 'PENDING' },
    { text: 'COMPLETED', value: 'COMPLETED' },
  ]

  return (
    <Modal
      maskClosable={false}
      visible={visible}
      title={isUpdate ? 'Update AMC' : 'Create AMC'}
      okText={isUpdate ? 'Update' : 'Create'}
      okButtonProps={{
        loading
      }}
      cancelText='Cancel'
      onCancel={() => {
        resetForm()
        onCancel()
      }}
      onOk={() => {

        form
          .validateFields()
          .then(values => {
            if (isUpdate) {
              onUpdate(values, resetForm)
            } else {
              onCreate(values, resetForm)
            }
          })
          .catch(info => console.log('Validate Failed:', info))
      }}
    >
      <Form
        form={form}
        layout='vertical'
        name='form_in_modal'
        initialValues={{
          date,
          status,
          amount,
          whichYear,
          modifier: 'public',
          ...data
        }}
      >
        <Form.Item
          name='amount'
          label='Amount'
          rules={[{ required: true, message: 'Please input your Amount!' }]}
        >
          <InputNumber
            min={0}
            style={{ width: '100%' }}
            placeholder='Enter Amount'
          />
        </Form.Item>
        <Form.Item
          name='whichYear'
          label='Year Of'
          rules={[{ required: true, message: 'Please input your Which Year!' }]}
        >
          <DatePicker
            picker='year'
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item
          name='paymentType'
          label='Select Payment Type'
          rules={[{ required: true, message: 'Please Select Payment Type!', whitespace: true }]}
        >
          <Select placeholder='Select Payment Type'>
            {paymentTypes.map((paymentType) => <Option key={paymentType.value} value={paymentType.value}>{paymentType.text}</Option>)}
          </Select>
        </Form.Item>
        <Form.Item
          name='status'
          label='Select Status Type'
          rules={[{ required: true, message: 'Please Select Status Type!', whitespace: true }]}
        >
          <Select placeholder='Select Status Type'>
            {statusTypes.map((statusType) => <Option key={statusType.value} value={statusType.value}>{statusType.text}</Option>)}
          </Select>
        </Form.Item>
        <Form.Item
          hasFeedback
          name='description'
          label='Description'
          rules={[{ required: true, message: 'Please write your description!', whitespace: true }]}
        >
          <Input.TextArea rows={5} />
        </Form.Item>
        <Form.Item name='date' style={{ display: 'none' }}>
          <DatePicker />
        </Form.Item>
      </Form>
    </Modal>
  )
}

const CreateTicketModal = (props) => {
  return (
    <div>
      <CollectionCreateForm
        {...props}
      />
    </div>
  )
}

export default CreateTicketModal
