import React, { useState } from 'react'
import { Layout, Drawer } from 'antd'
import { NavLink } from 'react-router-dom'

import LogoWhite from 'assets/images/logo-white.png'
import CustomHeader from './Header'
import MainRoutes from 'MainRoutes'
import CustomSider from './Sider'
import Menu from './Menu'
import './layout.css'

const { Content } = Layout

export default function LayoutWrapper(props) {
  const { location: { pathname } } = props
  const [collapsed, setCollapsed] = useState(false)
  const [visibleDrawer, setVisibleDrawer] = useState(false)

  return (
    <Layout>

      <CustomSider collapsed={collapsed} pathname={pathname} />
      <Layout className='site-layout'>
        {/* <div class="row notificationBar">
          <span class="notificationMsg">
            You are on new dashboard, access old site on the url <a href="#pricing-plans">backoffice.finideas.com</a> or <a href="#pricing-plans">click here</a>
          </span>
        </div> */}
        <CustomHeader
          collapsed={collapsed}
          toggle={() => setCollapsed(!collapsed)}
          drawerToggle={() => setVisibleDrawer(!visibleDrawer)}
        />
        <Drawer
          placement='left'
          closable={false}
          visible={visibleDrawer}
          className='layout-drawer'
          onClose={() => setVisibleDrawer(!visibleDrawer)}
          title={<NavLink to='/' className='nav-text'><img alt='Finideas' src={LogoWhite} /></NavLink>}
        >
          <Menu pathname={pathname} />
        </Drawer>
        <Content>
          <MainRoutes />
        </Content>
      </Layout>
    </Layout>
  )
}
