import React, { useState } from 'react'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import { useQuery } from '@apollo/react-hooks'
import { Select } from 'antd'
import get from 'lodash/get'
import map from 'lodash/map'

import Widget from 'components/Widget'
import { exportGraphAsImage } from 'utils/Graph'
import ChartError from 'components/Page404/ChartError'
// import ChartLoader from 'components/loaders/ChartLoader'
import { GET_NLV_INDIVIDUAL_REPORT } from '../../../Reports/graphql/Queries'
import Loader from 'components/loaders/Loader'

const Option = Select.Option

export default function ({ groups }) {
  const [groupId, setGroupId] = useState(undefined)
  const [selectedField, setSelectedField] = useState('NLV')
  const { data, loading, error } = useQuery(GET_NLV_INDIVIDUAL_REPORT, { variables: { groupId }, fetchPolicy: 'network-only' })

  // if (loading) return <ChartLoader title='NLV Individual Report' />
  if (error) return <ChartError title='NLV Individual Report' />
  // if (!get(data, 'nlvIndividualReport.length')) return <ChartError title='NLV Individual Report' desc='No Data' />

  let nlvIndividualReport
  if (!loading && get(data, 'nlvIndividualReport.length')) {
    nlvIndividualReport = data.nlvIndividualReport
  }

  const isEmpty = !get(data, 'nlvIndividualReport.length')

  function handleChange(value) {
    setSelectedField(value)
  }

  const divId = 'export-graph-NLV-Chart'

  function handleExport(value) {
    exportGraphAsImage('NLV-Individual-Report', value, divId)
  }

  function returnGroups() {
    return map(groups, (group) => {
      const { id, name } = group
      return <Option value={id}>{name}</Option>
    })
  }

  let renderData
  if (nlvIndividualReport) {
    renderData =
      nlvIndividualReport
        .map((reportData) => ({ 'name': reportData.Client, ...reportData }))
  }

  return (
    <Widget title={<h2 className='h4 gx-text-capitalize gx-mb-0'>NLV Individual Report</h2>} styleName='gx-text-center'>
      <div className='ant-row-flex gx-px-4 gx-pt-4'>
        <div className='gx-ml-auto'>
          <Select className='gx-mb-2 gx-select-sm' defaultValue='' onChange={value => setGroupId(value ? parseInt(value) : undefined)} style={{ minWidth: 100 }}>
            <Option value=''>All Clients</Option>
            {returnGroups()}
          </Select>
          &nbsp;
          <Select className='gx-mb-2 gx-select-sm' defaultValue='' onChange={handleChange} style={{ minWidth: 170 }}>
            <Option value='' disabled>Select Field</Option>
            <Option value='StockPnl'>Stock PNL</Option>
            <Option value='POSPnl'>POSPnl</Option>
            <Option value='LedgerBalance'>Ledger Balance</Option>
            <Option value='PnlBooked'>PNL Booked</Option>
            <Option value='PnlunBooked'>PNL Unbooked</Option>
            <Option value='PnlWithOutInterest'>PNL Without Interest</Option>
            <Option value='InterestSaved'>Interest Saved</Option>
            <Option value='PnlAfterInterest'>PNL After Interest</Option>
            <Option value='ProffitPayOut'>Profit PayOut</Option>
            <Option value='TotalExposure'>Total Exposure</Option>
            <Option value='StockReceived'>Stock Received</Option>
            <Option value='NLV'>NLV</Option>
          </Select>
          &nbsp;
          <Select className='gx-mb-2 gx-select-sm' value='1' onChange={handleExport}>
            <Option value='1' disabled>Export As</Option>
            <Option value='png'>PNG</Option>
            <Option value='jpeg'>JPEG</Option>
          </Select>
        </div>
      </div>
      {
        loading ?
          <div style={{ minHeight: 160, display: "flex", alignItems: 'center', justifyContent: 'center' }}>
            <Loader />
          </div>
          :
          isEmpty ?
            <h2 className='h4 gx-text-capitalize gx-mb-0' style={{ minHeight: 160, display: "flex", alignItems: 'center', justifyContent: 'center' }}>No Data</h2>
            :
            <ResponsiveContainer width='100%' height={250} id={divId}>
              <BarChart data={renderData}>
                <XAxis dataKey='name' />
                <YAxis />
                <CartesianGrid strokeDasharray='3 3' />
                <Tooltip />
                <Legend />
                <Bar dataKey={selectedField} fill='#003366' />
              </BarChart>
            </ResponsiveContainer>
      }
    </Widget>
  )
}
