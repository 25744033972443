import gql from 'graphql-tag'

export const GET_USERS_FOR_SELECT = gql`
query getUsers($where: UserCreateWhereInput, $skip: Int, $limit: Int){
  getUsers(where: $where, skip: $skip, limit: $limit) {
    users {
      id
      firstName
      lastName
      email
      userName
      clientCode
    }
  }
}
`

export const GET_USERS = gql`
query getUsers($where: UserCreateWhereInput, $skip: Int, $limit: Int){
  getUsers(where: $where, skip: $skip, limit: $limit) {
    users{
      id
      firstName
      lastName
      email
      password
      phone
      address
      cityId
      stateId
      countryId
      motherName
      fatherName
      dateOfBirth
      userName
      isActive
      role
      ratio
      keyId
      isAuto
      isAssociate
      exposure
      fundReceived
      initialExposure
      isKycDone
      completedSteps
      amcStartDate
      amcAmount
      sipStartDate
      sipAmount
      closedDate
      clientCode
      strategyCode
      token
      tokenExpiration
      createdAt
      updatedAt
      createdBy {
        id
      }
      updatedBy {
        id
      }
    }
    count
  }
}
`

export const GET_USER = gql`
query getUser($id: ID){
  getUser(where: { id: $id }) {
    id
  firstName
  lastName
  email
  password
  phone
  address
  cityId
  stateId
  countryId
  motherName
  fatherName
  dateOfBirth
  userName
  isActive
  role
  ratio
  keyId
  isAuto
  isAssociate
  exposure
  fundReceived
  initialExposure
  isKycDone
  completedSteps
  amcStartDate
  amcAmount
  sipStartDate
  sipAmount
  closedDate
  clientCode
  strategyCode
  token
  tokenExpiration
  createdAt
  updatedAt
    kyc {
      id
      panCard
      aadharCard
      passportSize
      signature
      cancelledChequeSnap
      bankStatement
    }
    createdBy {
      id
    }
    updatedBy {
      id
    }
  }
}
`

export const GET_CLIENT_USERS = gql`
query getClientUsers($where: UserWhereUniqueInput!, $skip: Int, $limit: Int){
  getClientUsers(where: $where, skip: $skip, limit: $limit) {
    users{
      id
      firstName
      lastName
      email
      password
      phone
      address
      cityId
      stateId
      countryId
      motherName
      fatherName
      dateOfBirth
      userName
      isActive
      role
      ratio
      keyId
      isAuto
      isAssociate
      exposure
      fundReceived
      initialExposure
      isKycDone
      completedSteps
      amcStartDate
      amcAmount
      sipStartDate
      sipAmount
      closedDate
      clientCode
      strategyCode
      token
      tokenExpiration
      createdAt
      updatedAt
      createdBy {
        id
      }
      updatedBy {
        id
      }
    }
    count
  }
}
`

export const GET_SYSTEM_USERS = gql`
query getSystemUsers($where: UserWhereUniqueInput!, $skip: Int, $limit: Int){
  getSystemUsers(where: $where, skip: $skip, limit: $limit) {
    users{
      id
      firstName
      lastName
      email
      password
      phone
      address
      cityId
      stateId
      countryId
      motherName
      fatherName
      dateOfBirth
      userName
      isActive
      role
      ratio
      keyId
      isAuto
      isAssociate
      exposure
      fundReceived
      initialExposure
      isKycDone
      completedSteps
      amcStartDate
      amcAmount
      sipStartDate
      sipAmount
      closedDate
      clientCode
      strategyCode
      token
      tokenExpiration
      createdAt
      updatedAt
      createdBy {
        id
      }
      updatedBy {
        id
      }
    }
    count
  }
}
`

export const GET_AMC = gql`
query getAllAmcs($clientId: Int, $whichYear: Int, $skip: Int, $limit: Int){
  getAllAmcs(where: { clientId: $clientId, whichYear: $whichYear }, skip: $skip, limit: $limit) {
    amc {
      id
      status
      amount
      whichYear
      paymentType
      description
      createdAt
      updatedAt
      client{
        id
        firstName
        lastName
      }
    }
    count
  }
}`

export const GET_SIP = gql`
query getAllSips($clientId: Int, $whichMonth: String, $skip: Int, $limit: Int){
  getAllSips(where: { clientId: $clientId, whichMonth: $whichMonth }, skip: $skip, limit: $limit) {
    sip{
      id
      status
      amount
      whichMonth
      paymentType
      description
      createdAt
      updatedAt
      client{
        id
        firstName
        lastName
      }
    }
    count
  }
}`

export const GET_LEAD_USERS = gql`
query getLeadUsers($userId: Int!){
  getLeadUsers(where: { userId: $userId })
}`
