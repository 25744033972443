import React, { useState, useContext } from 'react'
import { Col, Button, Tooltip, Table, Select } from 'antd'
import { EditTwoTone } from '@ant-design/icons'
import { useQuery } from '@apollo/react-hooks'
import get from 'lodash/get'
import moment from 'moment'

import { CREATE_SIP, UPDATE_SIP } from 'modules/Users/graphql/Mutations'
import { GET_SIP } from 'modules/Users/graphql/Queries'
import openNotification from 'utils/Notification'
import CreateSIPModal from 'modules/SIP/Modal'
import Page404 from 'components/Page404'
import { AppContext } from 'AppContext'
import client from 'apollo'

const { Option } = Select
const months = ['All', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

export default function ({ id, sipAmount, createdAt }) {
  let tableData = []
  const clientId = id ? parseInt(id) : undefined
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [filters, setFilters] = useState({})
  const [isUpdate, setIsUpdate] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const { state: { isClient } } = useContext(AppContext)
  const [updateData, setUpdateData] = useState(undefined)
  const [showFormModal, setShowFormModal] = useState(false)
  const [showFormLoading, setShowFormLoading] = useState(false)

  const { data, loading, error } = useQuery(GET_SIP, { variables: { clientId, ...filters, skip, limit }, fetchPolicy: 'network-only' })

  if (error) return <Page404 />

  let totalCount = 0
  if (!loading && get(data, 'getAllSips.sip')) {
    totalCount = data.getAllSips.count
    tableData = data.getAllSips.sip.map((user, key) => ({ key: key.toString(), ...user }))
  }

  function handlePagination(page) {
    setSkip((page - 1) * limit)
    setCurrentPage(page)
  }

  function handlePageSizeChange(current, size) {
    setLimit(size)
  }

  async function handleCreate(values, resetForm) {
    if (values.multiple) {
      const { whichMonthFrom, whichMonthTo } = values
      const dateMoment = moment(values.date)
      const total = (months.findIndex((m) => m === whichMonthTo) - months.findIndex((m) => m === whichMonthFrom)) + 1
      const monthsCount = []
      for (let index = 1; index <= total; index++) {
        monthsCount.push(months[index])
      }
      delete values.date
      delete values.multiple
      delete values.whichMonthTo
      delete values.whichMonthFrom
      try {
        for (const monthCount of monthsCount) {
          const date = `${moment().format('YYYY')}-${months.findIndex((m) => m === monthCount)}-${dateMoment.format('D')}`
          const dataValues = { ...values, whichMonth: monthCount }
          dataValues.startDate = moment(new Date(date)).add(1, 'days');
          dataValues.endDate = moment(new Date(date)).add(1, 'months');
          await client.mutate({
            mutation: CREATE_SIP,
            variables: { data: { ...dataValues, clientId: parseInt(clientId) } },
            refetchQueries: [{ query: GET_SIP, variables: { clientId, ...filters, skip, limit }, fetchPolicy: 'network-only' }]
          })
            .catch((err) => console.log(err))
        }
        openNotification('success', 'SIPs Created Successfully')
        setShowFormLoading(false)
        setShowFormModal(false)
        resetForm()
      } catch (e) {
        console.log(e)
        setShowFormLoading(false)
      }
    } else {
      delete values.multiple
      const dateMoment = moment(values.date)
      const date = `${moment().format('YYYY')}-${months.findIndex((m) => m === values.whichMonth)}-${dateMoment.format('D')}`
      values.startDate = moment(new Date(date)).add(1, 'days');
      values.endDate = moment(new Date(date)).add(1, 'months');
      delete values.date
      setShowFormLoading(true)
      client.mutate({
        mutation: CREATE_SIP,
        variables: { data: { ...values, clientId: parseInt(clientId) } },
        refetchQueries: [{ query: GET_SIP, variables: { clientId, ...filters, skip, limit }, fetchPolicy: 'network-only' }]
      })
        .then((res) => {
          openNotification('success', 'SIP Created Successfully')
          setShowFormModal(false)
          resetForm()
        })
        .catch((err) => console.log(err))
        .finally(() => setShowFormLoading(false))
    }
  }

  function handleUpdate(values, resetForm) {
    setShowFormLoading(true)
    client.mutate({
      mutation: UPDATE_SIP,
      variables: { data: values, id: updateData.id },
      refetchQueries: [{ query: GET_SIP, variables: { clientId, ...filters, skip, limit }, fetchPolicy: 'network-only' }]
    })
      .then((res) => {
        openNotification('success', 'SIP Updated Successfully')
        setShowFormModal(false)
        setIsUpdate(false)
        setUpdateData(undefined)
        resetForm()
      })
      .catch((err) => console.log(err))
      .finally(() => setShowFormLoading(false))
  }

  function renderAction(record) {
    return <div className='action-icons'>
      <Tooltip title='Edit'>
        <EditTwoTone
          onClick={() => {
            setIsUpdate(true)
            setUpdateData(record)
            setShowFormModal(true)
          }}
        />
      </Tooltip>
    </div>
  }

  function handleSearch(value, confirm, filedName) {
    value = typeof value === 'object' ? value[0] : value
    confirm()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    tempFilters[filedName] = value
    setFilters(tempFilters)
    setCurrentPage(1)
  }

  function handleReset(clearFilters, filedName) {
    clearFilters()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    delete tempFilters[filedName]
    setFilters(tempFilters)
    setCurrentPage(1)
  }

  // const getColumnSearchProps = dataIndex => ({
  //   filterDropdown: ({ setSelectedKeys, confirm, clearFilters }) => (
  //     <div style={{ padding: 8 }}>
  //       <DatePicker
  //         picker='year'
  //         onChange={e => {
  //           if (e) {
  //             handleSearch(parseInt(e.format('YYYY')), confirm, dataIndex)
  //           } else {
  //             handleReset(clearFilters, dataIndex)
  //           }
  //         }}
  //       />
  //     </div>
  //   ),
  // })

  const getColumnDropDownProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Select
          placeholder='Select Month'
          style={{ minWidth: 120 }}
          onChange={e => {
            if (e === 'All') {
              handleReset(clearFilters, dataIndex)
            } else {
              handleSearch(e, confirm, dataIndex)
            }
          }}
        >
          {months.map((month) => <Option key={month} value={month}>{month}</Option>)}
        </Select>
      </div>
    ),
  })

  const columns = [
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Month',
      dataIndex: 'whichMonth',
      key: 'whichMonth',
      ...getColumnDropDownProps('whichMonth')
    },
    {
      title: 'Payment Type',
      dataIndex: 'paymentType',
      key: 'paymentType',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: date => moment(date).format('Do MMMM YYYY'),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => renderAction(record)
    },
  ]

  return (
    <>
      {
        showFormModal &&
        <CreateSIPModal
          date={createdAt}
          data={updateData}
          amount={sipAmount}
          isUpdate={isUpdate}
          visible={showFormModal}
          onCreate={handleCreate}
          onUpdate={handleUpdate}
          loading={showFormLoading}
          onCancel={() => {
            setShowFormModal(false)
            setUpdateData(undefined)
            setIsUpdate(false)
          }}
        />
      }
      {
        !isClient && clientId &&
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button
            key='2'
            type='primary'
            onClick={() => {
              setShowFormModal(true)
              setIsUpdate(false)
              setUpdateData(undefined)
            }}
          >
            Add SIP
              </Button>
        </Col>
      }
      <Col span={24}>
        <Table
          loading={loading}
          expandable={{
            expandedRowRender: record => <p style={{ margin: 0 }}>{record.description}</p>,
            rowExpandable: record => record.name !== 'Not Expandable',
          }}
          scroll={{ x: true }}
          dataSource={tableData}
          columns={isClient ? columns.slice(0, columns.length - 1) : columns}
          pagination={{ pageSize: limit, total: totalCount, onChange: handlePagination, current: currentPage, onShowSizeChange: handlePageSizeChange }}
        />
      </Col>
    </>
  )
}
