import React from 'react'
import { Table } from 'antd'

const columns = [
  {
    title: 'F&O Open Position',
    className: 'report-title main-title',
    children: [
      {
        title: 'Script',
        dataIndex: 'Script',
        key: 'Script',
        className: 'report-text'
      },
      {
        title: 'TotalQty',
        dataIndex: 'TotalQty',
        key: 'TotalQty',
        className: 'report-numeric'
      },
      {
        title: 'TradedRate',
        dataIndex: 'TradedRate',
        key: 'TradedRate',
        className: 'report-numeric'
      },
      {
        title: 'CMP',
        dataIndex: 'CMP',
        key: 'CMP',
        className: 'report-numeric'
      },
      {
        title: 'Value',
        dataIndex: 'Value',
        key: 'Value',
        className: 'report-numeric'
      },
      {
        title: 'Pnl',
        dataIndex: 'Pnl',
        key: 'Pnl',
        className: 'report-numeric'
      }
    ]
  }
]

export default function ({ reportData }) {
  const data = reportData.map((report, key) => { return { key, ...report } })

  return (
    <Table
      dataSource={data}
      columns={columns}
      // scroll={{ x: true, y: 240 }}
      pagination={{ pageSize: 1000, hideOnSinglePage: true }}
    />
  )
}
