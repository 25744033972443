import React from 'react'
import moment from 'moment'
import find from 'lodash/find'
import pick from 'lodash/pick'
import omit from 'lodash/omit'
import startCase from 'lodash/startCase'
import { NavLink } from 'react-router-dom'

import countries from './countries.json'
import states from './states.json'
import cities from './cities.json'

const contactFields = ['email', 'phone', 'userName', 'clientCode', 'strategyCode']
const staffUserFields = ['firstName', 'lastName', 'email', 'phone', 'dateOfBirth', 'userName']
const userFields = ['firstName', 'lastName', 'email', 'phone', 'address', 'cityId', 'stateId', 'countryId', 'motherName', 'fatherName', 'dateOfBirth', 'userName', 'keyId', 'fundReceived', 'amcStartDate', 'sipStartDate', 'closedDate', 'initialExposure', 'clientCode', 'strategyCode']

export function getName(data) {
  const { firstName, lastName } = data
  if (!firstName && !lastName)
    return '-'
  if (firstName && lastName)
    return `${firstName} ${lastName}`
  if (lastName)
    return lastName
  return firstName
}

export function getFirstName(data) {
  const { firstName } = data
  if (!firstName)
    return '-'
  return firstName
}

export function getLastName(data) {
  const { lastName } = data
  if (!lastName)
    return '-'
  return lastName
}

export function getEmail(data) {
  const { email } = data
  if (!email)
    return ''
  return email
}

export function getUserNavLinkByObject(data, isClient) {
  if (!data) return ''
  if (isClient) return getName(data)
  const nav = (!!data.role && data.role === 'CLIENT') ? 'clients' : 'users'
  return <NavLink to={`/${nav}/${data.id}`}>{getName(data)}</NavLink>
}

export function getFirstLetter(data) {
  const name = getName(data)
  return name.charAt(0).toUpperCase()
}

export function getCityState(data) {
  const { cityId, stateId } = data
  if (!cityId && !stateId)
    return '-'
  if (cityId && !stateId && find(cities, { id: cityId }))
    return find(cities, { id: cityId }).name
  if (!cityId && stateId && find(states, { id: stateId }))
    return find(states, { id: stateId }).name
  if (cityId && stateId && find(states, { id: stateId }) && find(cities, { id: cityId }))
    return `${find(cities, { id: cityId }).name}, ${find(states, { id: stateId }).name}`
  return '-'
}

export function userDocuments(kyc) {
  const documents = omit(kyc, 'id', '__typename')
  return Object.keys(documents).map((key) => {
    const desc = documents[key] ? <a href={documents[key]} target='_blank' rel='noopener noreferrer'>Download</a> : '-'
    const icon = documents[key] ? ['jpeg', 'png', 'jpg'].includes(documents[key].split('.').pop()) ? 'image' : 'editor' : 'editor'
    return { title: startCase(key), icon, desc }
  })
}

export function staffUsersData(userData) {
  return pick(userData, staffUserFields)
}

export function userDetails(details) {
  details = pick(details, userFields)
  return Object.keys(details).map((key) => {
    switch (key) {
      case 'amcStartDate':
      case 'sipStartDate':
      case 'closedDate':
        const closedDate = details[key] ? moment(details[key]).format('Do MMMM YYYY') : '-'
        return { title: startCase(key), icon: 'datepicker', desc: closedDate }
      case 'fundReceived':
        return { title: startCase(key), icon: 'revenue-new', desc: details[key] }
      case 'birthday':
      case 'dateOfBirth':
        const birthday = details[key] ? moment(details[key]).format('DD MMMM YYYY') : '-'
        return { title: startCase(key), icon: 'birthday-new', desc: birthday }
      case 'cityId':
        let city = find(cities, { id: details[key] })
        city = city ? city.name : ''
        return { title: 'City', icon: 'map-drawing', desc: city }
      case 'stateId':
        let state = find(states, { id: details[key] })
        state = state ? state.name : ''
        return { title: 'State', icon: 'map-drawing', desc: state }
      case 'countryId':
        let country = find(countries, { id: details[key] })
        country = country ? country.name : ''
        return { title: 'Country', icon: 'map-drawing', desc: country }
      case 'address':
        return { title: startCase(key), icon: 'map-drawing', desc: details[key] }
      case 'userName':
        return { title: startCase(key), icon: 'profile2', desc: <span className='highlight'>{details[key]}</span> }
      case 'ketId':
      case 'clientCode':
      case 'strategyCode':
        return { title: startCase(key), icon: 'inputnumber', desc: <span className='highlight'>{details[key]}</span> }
      case 'phone':
        return { title: startCase(key), icon: 'phone', desc: details[key] ? <a href={`tel:${details[key]}`}>{details[key]}</a> : '' }
      case 'email':
        return { title: startCase(key), icon: 'email', desc: details[key] ? <a href={`mailto:${details[key]}`}>{details[key]}</a> : '' }
      default:
        return { title: startCase(key), icon: 'auth-screen', desc: details[key] }
    }
  })
}

export function contactDetails(details) {
  details = pick(details, contactFields)
  return Object.keys(details).map((key) => {
    switch (key) {
      case 'clientCode':
      case 'strategyCode':
        return { title: startCase(key), icon: 'inputnumber', desc: <span className='highlight'>{details[key]}</span> }
      case 'phone':
        return { title: startCase(key), icon: 'phone', desc: details[key] ? <a href={`tel:${details[key]}`}>{details[key]}</a> : '' }
      case 'email':
        return { title: startCase(key), icon: 'email', desc: details[key] ? <a href={`mailto:${details[key]}`}>{details[key]}</a> : '' }
      default:
        return { title: startCase(key), icon: 'auth-screen', desc: details[key] }
    }
  })
}


export const userRoles =
{
  'ADMIN': [
    { text: 'ADMIN', value: 'ADMIN' },
    { text: 'STAFF', value: 'STAFF' },
    { text: 'MASTER ASSOCIATE', value: 'MASTER_ASSOCIATE' },
    { text: 'ASSOCIATE', value: 'ASSOCIATE' },
    { text: 'SALES EXECUTIVE', value: 'SALES_EXECUTIVE' }
  ],
  'STAFF': [
    { text: 'STAFF', value: 'STAFF' },
    { text: 'MASTER ASSOCIATE', value: 'MASTER_ASSOCIATE' },
    { text: 'ASSOCIATE', value: 'ASSOCIATE' },
    { text: 'SALES EXECUTIVE', value: 'SALES_EXECUTIVE' }
  ],
  'MASTER_ASSOCIATE': [
    { text: 'ASSOCIATE', value: 'ASSOCIATE' },
  ],
  'SALES_EXECUTIVE': [
    { text: 'MASTER ASSOCIATE', value: 'MASTER_ASSOCIATE' },
    { text: 'ASSOCIATE', value: 'ASSOCIATE' },
    { text: 'SALES EXECUTIVE', value: 'SALES_EXECUTIVE' }
  ]
}
