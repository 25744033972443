import React from 'react'
import get from 'lodash/get'
import { Row, Col, Select } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import Chart from 'kaktana-react-lightweight-charts'

import Widget from '../../../components/Widget'
import { exportGraphAsImage } from 'utils/Graph'
import { GET_NIFTY_CANDLE_CHART } from '../graphql/Queries'
import ChartLoader from '../../../components/loaders/ChartLoader'
import ChartError from '../../../components/Page404/ChartError'

const Option = Select.Option

const chartOptions = {
  alignLabels: true,
  timeScale: {
    rightOffset: 12,
    barSpacing: 3,
    fixLeftEdge: true,
    lockVisibleTimeRangeOnResize: true,
    rightBarStaysOnScroll: true,
    borderVisible: false,
    borderColor: '#fff000',
    visible: true,
    timeVisible: false,
    secondsVisible: false
  }
}
export default function () {
  const { data, loading, error } = useQuery(GET_NIFTY_CANDLE_CHART, { fetchPolicy: 'network-only' })

  if (loading) return <ChartLoader title='Nifty 50' />
  if (error) return <ChartError title='Nifty 50' />
  if (!get(data, 'niftyCandleChart.length')) return <ChartError title='Nifty 50' desc='No Data' />

  const candlestickSeries = [{
    data: data.niftyCandleChart.map((chartData, key) => {
      return {
        time: chartData.IndexDate,
        open: chartData.OpenIndexValue,
        high: chartData.HighIndexValue,
        low: chartData.LowIndexValue,
        close: chartData.ClosingIndexValue,
      }
    })
  }]
  const divId = 'export-graph-nifty'

  const previousData = get(data, 'niftyCandleChart')[get(data, 'niftyCandleChart.length') - 1]

  function handleExport(value) {
    exportGraphAsImage('Nifty 50', value, divId)
  }

  return (
    <Widget title={<h2 className='h4 gx-text-capitalize gx-mb-0'>Nifty 50</h2>} styleName='gx-text-center'>
      <div className='ant-row-flex gx-px-4'>
        <div className='gx-ml-auto' style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex' }}>
            <p className="gx-mr-4 gx-mb-0"><span className="gx-size-8 gx-bg-dark gx-rounded-xs gx-d-inline-block gx-mr-1"></span> Previous Date: {previousData.IndexDate}</p>
            <p className="gx-mr-4 gx-mb-0"><span className="gx-size-8 gx-bg-dark gx-rounded-xs gx-d-inline-block gx-mr-1"></span> Previous Day Close: {previousData.ClosingIndexValue}</p>
            <p className="gx-mb-0"><span className="gx-size-8 gx-bg-dark gx-rounded-xs gx-d-inline-block gx-mr-1"></span> Previous Day Change: {previousData.Change}</p>
          </div>
          <Select className='gx-mb-2 gx-select-sm gx-mb-0' value='1' onChange={handleExport}>
            <Option value='1' disabled>Export As</Option>
            <Option value='png'>PNG</Option>
            <Option value='jpeg'>JPEG</Option>
          </Select>
        </div>
      </div>
      <Row>
        <Col xl={23} lg={23} md={23} sm={23} xs={23} id={divId}>
          <Chart options={chartOptions} candlestickSeries={candlestickSeries} autoWidth height={250} />
        </Col>
      </Row>
    </Widget>
  )
}
