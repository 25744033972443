import React, { Fragment, useState } from 'react'
import { EditTwoTone, SearchOutlined, MenuOutlined, EditOutlined, NotificationOutlined, ExclamationCircleOutlined, DeleteOutlined } from '@ant-design/icons'
import { PageHeader, Button, Menu, Dropdown, Tooltip, Input, Space, Table, Modal } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import { NavLink } from 'react-router-dom'
import omit from 'lodash/omit'
import get from 'lodash/get'

import client from 'apollo'
import Page404 from 'components/Page404'
import { GET_GROUP } from './graphql/Queries'
import Loader from 'components/loaders/Loader'
import CustomMeta from 'components/CustomMeta'
import CreateGroupModal from './CreateGroupModal'
import openNotification from 'utils/Notification'
import { getLastName, getFirstName } from 'utils/User'
import NotificationGroupModal from './NotificationGroupModal'
import { UPDATE_GROUP, SEND_GROUP_NOTIFICATION, DELETE_GROUP } from './graphql/Mutations'

const { confirm } = Modal

function handleSearchFilters(users, filters) {
  filters = omit(filters, 'role')
  users = users.map((user, key) => ({ key: key.toString(), ...user }))
  Object.keys(filters).forEach((key) => {
    if (!!filters[key]) {
      users = users.filter((user) => user[key].toLowerCase().includes(filters[key].toLowerCase()))
    }
  })
  return users
}

export default function (props) {
  let tableData = []
  let groupDetails = {}
  const [currentPage, setCurrentPage] = useState(1)
  const [visibleMenu, setVisibleMenu] = useState(false)
  const [showFormModal, setShowFormModal] = useState(false)
  const [filters, setFilters] = useState({ role: 'CLIENT' })
  const [showNotificationModal, setShowNotificationModal] = useState(false)

  const { history, match: { params: { groupId } } } = props
  const variables = { where: { id: groupId } }

  const { data, loading, error } = useQuery(GET_GROUP, { variables, fetchPolicy: 'network-only' })

  if (loading) return <Loader />
  if (error) return <Page404 />

  if (!loading && get(data, 'getGroup')) {
    groupDetails = data.getGroup
    tableData = handleSearchFilters(groupDetails.users, filters)
  }

  function showConfirm() {
    confirm({
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
      title: `Do you want to delete this group?`,
      content: `When clicked the OK button, this group will be Deleted`,
      onOk() {
        client
          .mutate({ mutation: DELETE_GROUP, variables: { id: groupId } })
          .then(() => history.goBack())
          .catch((err) => console.log(err))
      }
    })
  }

  const menu = (
    <Menu>
      <Menu.Item
        key='1'
        onClick={() => {
          setVisibleMenu(false)
          setShowFormModal(true)
        }}>
        <EditOutlined /> Edit
      </Menu.Item>
      <Menu.Item
        key='2'
        onClick={() => {
          setVisibleMenu(false)
          setShowNotificationModal(true)
        }}>
        <NotificationOutlined /> Send Notification
      </Menu.Item>
      <Menu.Item
        key='3'
        onClick={() => {
          setVisibleMenu(false)
          showConfirm()
        }}>
        <DeleteOutlined /> Delete
      </Menu.Item>
    </Menu>
  )

  function handleHideFormModal() {
    setShowFormModal(false)
  }

  function handleNotificationModal(values, resetForm) {
    const getUserPhoneEmails = values.sendVia === 'EMAIL' ? 'email' : 'phone'
    const userPhoneEmails = groupDetails.users.map((user) => user[getUserPhoneEmails])
    const data = { ...values, userPhoneEmails }
    client.mutate({ mutation: SEND_GROUP_NOTIFICATION, variables: { data, id: groupId } })
      .then((res) => {
        openNotification('success', 'Notification Sent')
        setShowNotificationModal(false)
        resetForm()
      })
      .catch((err) => console.log(err))
  }

  function handleUpdateGroup(values, resetForm) {
    const data = values
    client.mutate({ mutation: UPDATE_GROUP, variables: { data, id: groupId }, refetchQueries: [{ query: GET_GROUP, variables }] })
      .then((res) => {
        openNotification('success', 'Group Updated Successfully')
        handleHideFormModal()
        resetForm()
      })
      .catch((err) => console.log(err))
  }

  function renderAction(record) {
    return (
      <div className='action-icons'>
        <Tooltip title='Edit'>
          <EditTwoTone onClick={() => history.push(`/clients/${record.id}/edit`)} />
        </Tooltip>
      </div>
    )
  }

  function handlePagination(page) {
    setCurrentPage(page)
  }

  function handleSearch(value, confirm, filedName) {
    value = typeof value === 'object' ? value[0] : value
    confirm()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    tempFilters[filedName] = value
    setFilters(tempFilters)
    setCurrentPage(1)
  }

  function handleReset(clearFilters, filedName) {
    clearFilters()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    tempFilters[filedName] = ''
    setFilters(tempFilters)
    setCurrentPage(1)
  }

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys[0], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters, dataIndex)} size='small' style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
  })

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      render: (text, record) => <NavLink to={`/clients/${record.id}`}>{getFirstName(record)}</NavLink>,
      ...getColumnSearchProps('firstName')
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      render: (text, record) => <NavLink to={`/clients/${record.id}`}>{getLastName(record)}</NavLink>,
      ...getColumnSearchProps('lastName')
    },
    {
      title: 'Username',
      dataIndex: 'userName',
      key: 'userName',
      ...getColumnSearchProps('userName')
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: text => <a href={`mailto:${text}`}>{text}</a>,
      ...getColumnSearchProps('email')
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      render: text => <a href={`tel:${text}`}>{text}</a>,
      ...getColumnSearchProps('phone')
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => renderAction(record)
    },
  ]

  return (
    <Fragment>
      <CustomMeta title={groupDetails.name} description='Groups' />
      <PageHeader
        className='box'
        onBack={() => history.goBack()}
        title={groupDetails.name}
        extra={[
          window.innerWidth >= 991 ?
            <Fragment key='0'>
              <Button key='1' type='primary' onClick={() => setShowNotificationModal(true)}>Send Notification</Button>
              <Button key='2' type='primary' onClick={() => setShowFormModal(true)}>Edit</Button>
              <Button key='3' type='primary' onClick={() => showConfirm()}>Delete</Button>
            </Fragment>
            :
            <Dropdown onVisibleChange={() => setVisibleMenu(false)} key='0' overlay={menu} onClick={() => setVisibleMenu(!visibleMenu)} visible={visibleMenu}>
              <Button><MenuOutlined /></Button>
            </Dropdown>
        ]}
      />
      {showNotificationModal &&
        <NotificationGroupModal
          visible={showNotificationModal}
          onSend={handleNotificationModal}
          onCancel={() => setShowNotificationModal(false)}
        />
      }
      {showFormModal &&
        <CreateGroupModal
          isEdit={true}
          visible={showFormModal}
          onUpdate={handleUpdateGroup}
          selectedGroup={groupDetails}
          onCancel={() => handleHideFormModal()}
        />
      }
      <Table
        loading={loading}
        columns={columns}
        scroll={{ x: true }}
        dataSource={tableData}
        pagination={{ pageSize: 1000, hideOnSinglePage: true, total: tableData.length, onChange: handlePagination, current: currentPage }}
      />
    </Fragment>
  )
}
