import React from 'react'
import { Col } from 'antd'
import get from 'lodash/get'
import { useQuery } from '@apollo/react-hooks'

import { generateDashboardData } from 'utils/misc'
import { GET_DASHBOARD_COUNTS } from '../graphql/Queries'
import IconWithTextCard from '../Components/IconWithTextCard'

export default function () {
  let dashboardData
  const { data, loading } = useQuery(GET_DASHBOARD_COUNTS, { fetchPolicy: 'network-only' })

  if (loading || !get(data, 'dashboardCounters')) return <></>

  if (get(data, 'dashboardCounters')) {
    dashboardData = generateDashboardData(get(data, 'dashboardCounters'))
  }

  return (
    dashboardData && dashboardData.map((data, key) =>
      <Col key={key} xl={6} lg={12} md={12} sm={12} xs={24}>
        <IconWithTextCard {...data} />
      </Col>
    )
  )
}
