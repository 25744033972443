import React from 'react'
import { Steps, Button, message } from 'antd'
import { SolutionOutlined, SmileOutlined, UserSwitchOutlined, FileDoneOutlined, MailOutlined, WalletOutlined, UserOutlined, LineChartOutlined } from '@ant-design/icons'

import { UPDATE_USER } from '../../Users/graphql/Mutations'
import { GET_USER } from '../../Users/graphql/Queries'
import Widget from 'components/Widget'
import client from 'apollo'

const { Step } = Steps

const defaultSteps = ['KYC', 'DOCUMENT_COLLECTION', 'MEMBER_FORM_SUBMIT', 'TERM_MAIL', 'CHEQUE_COLLECTION', 'AMC_CHEQUE_COLLECTION', 'ACCOUNT_MAPPING', 'ELIGIBILITY_FOR_TRADING', 'DONE']

export default function ({ clientId, completedStep, isClient }) {
  const currentStep = defaultSteps.findIndex((defaultStep) => defaultStep === completedStep)

  function handleSteps(isPrevious) {
    const index = isPrevious ? currentStep - 1 : currentStep + 1
    const completedSteps = index === -1 ? null : defaultSteps[index]
    const queryVariables = { id: clientId, data: { completedSteps } }

    client.mutate({ mutation: UPDATE_USER, variables: queryVariables, refetchQueries: [{ query: GET_USER, variables: { id: clientId } }] })
      .then(() => message.success('Client Updated Successfully!'))
      .catch((err) => console.log(err))
  }

  return (
    <Widget>
      <Steps current={currentStep}>
        <Step title='KYC' description='KYC at Finideas end' icon={<FileDoneOutlined />} />
        <Step title='Documents' description='Documents collection' icon={<SolutionOutlined />} />
        <Step title='Member KYC' description='Member end form submit and KYC' icon={<UserSwitchOutlined />} />
        <Step title='Terms Mail' description='Terms and Condition E-Mail' icon={<MailOutlined />} />
        <Step title='Cheque' description='Cheque Collection (Fund)' icon={<WalletOutlined />} />
        <Step title='AMC' description='AMC Cheque Collection' icon={<WalletOutlined />} />
        <Step title='Account' description='Account mapping at member end' icon={<UserOutlined />} />
        <Step title='Eligibility' description='Eligibility for trading' icon={<LineChartOutlined />} />
        <Step title='Done' description='Profile Completed' icon={<SmileOutlined />} />
      </Steps>
      {
        !isClient &&
        <div className='steps-action'>
          {currentStep !== -1 && <Button type='primary' onClick={() => handleSteps(true)}>Previous</Button>}
          {currentStep !== 8 && <Button type='primary' onClick={() => handleSteps()}> {currentStep === 7 ? 'Done' : 'Next'}</Button>}
        </div>
      }
    </Widget>
  )
}
