import React, { useContext, useState, Fragment } from 'react'
import { MenuOutlined, MenuUnfoldOutlined, MenuFoldOutlined, EyeOutlined, PoweroffOutlined } from '@ant-design/icons'
import { Layout, Avatar, Popover } from 'antd'
import omit from 'lodash/omit'

import { CHANGE_PASSWORD } from 'modules/Users/graphql/Mutations'
import ChangePassword from 'components/ChangePassword'
import { getName, getFirstLetter } from 'utils/User'
import openNotification from 'utils/Notification'
import { AppContext } from 'AppContext'
import history from 'CustomHistory'
import client from 'apollo'

const { Header } = Layout

export default function ({ collapsed, toggle, drawerToggle }) {
  const color = '#7265e6'
  const [visible, setVisible] = useState(false)
  const [visibleMenu, setVisibleMenu] = useState(false)
  const { state: { currentUser, isClient } } = useContext(AppContext)

  const userMenuOptions = (
    <ul className='gx-user-popover'>
      <li
        onClick={() => {
          setVisibleMenu(false)
          history.push('/profile')
        }}>
        <i className='icon icon-avatar -flex-column-reverse menu-profile'></i> Profile
        </li>
      <li
        onClick={() => {
          setVisibleMenu(false)
          handleVisible()
        }}>
        <EyeOutlined /> Change Password
        </li>
      <li onClick={() => history.push('/logout')}>
        <PoweroffOutlined /> Logout
        </li>
    </ul>
  )

  function handleVisible() {
    setVisible(!visible)
  }

  function handleChangePassword(values, resetForm) {
    const queryVariables = omit(values, 'confirm')
    client.mutate({ mutation: CHANGE_PASSWORD, variables: queryVariables })
      .then((res) => {
        openNotification('success', 'Password Changed Successfully')
        handleVisible()
        resetForm()
        history.push('/logout')
      })
      .catch((err) => console.log(err))
  }

  return (
    <Fragment>
      {visible && <ChangePassword visible={visible} onCreate={handleChangePassword} onCancel={handleVisible} />}
      <Header className='site-layout-background'>
        {React.createElement(window.innerWidth >= 991 ? collapsed ? MenuUnfoldOutlined : MenuFoldOutlined : MenuOutlined, {
          className: 'trigger',
          onClick: () => window.innerWidth >= 991 ? toggle() : drawerToggle(),
        })}
        <div className='gx-flex-row gx-align-items-center gx-avatar-row'>
          <Popover placement='bottomRight' onVisibleChange={() => setVisibleMenu(!visibleMenu)} visible={visibleMenu} content={userMenuOptions} trigger='click'>
            <div>
              <Avatar style={{ backgroundColor: color, verticalAlign: 'middle' }} size='default' className='gx-pointer gx-mr-2'>{getFirstLetter(currentUser)}</Avatar>
              <span className='gx-avatar-name'>{getName(currentUser)} <i className='icon icon-chevron-down gx-fs-xxs gx-ml-2' /></span>
              {!isClient && <div className='gx-avatar-role'><span className='gx-avatar-name'>{currentUser.role.split('_').join(' ').toLowerCase()}</span></div>}
            </div>
          </Popover>
        </div>
      </Header>
    </Fragment>
  )
}
