import React from 'react'
import { Table } from 'antd'

const columns = [
  {
    title: 'Average Index Report',
    className: 'report-title main-title',
    children: [
      {
        title: 'Entry Date',
        dataIndex: 'entrydate',
        key: 'entrydate',
        className: 'report-text'
      },
      {
        title: 'Type',
        dataIndex: 'Type',
        key: 'Type',
        className: 'report-text'
      },
      {
        title: 'Closing Index Value',
        dataIndex: 'ClosingIndexValue',
        key: 'ClosingIndexValue',
        className: 'report-numeric'
      },
      {
        title: 'Qty',
        dataIndex: 'Qty',
        key: 'Qty',
        className: 'report-numeric'
      },
      {
        title: 'Value',
        dataIndex: 'Value',
        key: 'Value',
        className: 'report-numeric'
      }
    ]
  }
]

export default function ({ reportData }) {
  const data = reportData.map((report, key) => { return { key, ...report } })

  return (
    <Table
      columns={columns}
      dataSource={data}
      className='average-index-report'
      pagination={{ pageSize: 1000, hideOnSinglePage: true }}
    // scroll={{ x: true, y: 'calc(100vh - 24px - 24px - 72px - 36px - 35px)' }}
    />
  )
}
