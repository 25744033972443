import React from 'react'
import get from 'lodash/get'
import { useQuery } from '@apollo/react-hooks'

import Chart from './Chart'
import ChartLoader from 'components/loaders/ChartLoader'
import { GET_GROUPS_NAME } from 'modules/Groups/graphql/Queries'

export default function () {
  let groups
  const { data, loading } = useQuery(GET_GROUPS_NAME, { fetchPolicy: 'network-only' })
  if (!loading && get(data, 'getGroups.groups')) {
    groups = data.getGroups.groups.map((group, key) => (group))
  }

  if (loading) return <ChartLoader title='Profit Loss Report' />
  return <Chart groups={groups} />
}
