import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Form, Input } from 'antd'

import { SEND_PASSWORD_LINK } from './graphql/Queries'
import openNotification from 'utils/Notification'
import Logo from 'assets/images/logo.png'
import client from 'apollo'
import './index.css'

const FormItem = Form.Item

export default function ({ history }) {

  function onFinish(values) {
    const { email } = values
    client.mutate({ mutation: SEND_PASSWORD_LINK, variables: { email } })
      .then((res) => {
        openNotification('success', 'Reset password link has been sent to your email')
        history.push('/login')
      })
      .catch((err) => console.log(err))
  }

  return (
    <div className='gx-login-container forgot-password-wrapper'>
      <div className='gx-login-content'>
        <div className='gx-login-header'>
          <img alt='Finideas' src={Logo} />
        </div>
        <div className='gx-mb-4'>
          <h2>Forgot Your Password ?</h2>
          <p>Don't worry. Recovering the password is easy. Enter the email you have registered with Finideas</p>
        </div>
        <Form
          name='basic'
          onFinish={onFinish}
          initialValues={{ remember: true }}
          className='gx-signin-form gx-form-row0'>
          <FormItem
            name='email'
            rules={[
              { required: true, message: 'Please input your E-mail!' },
              { message: 'The input is not valid E-mail!', pattern: new RegExp(/^[a-z0-9._-]+@[a-z0-9.-]+\.[a-z]{2,4}$/) }
            ]}
          >
            <Input className='gx-input-lineheight' type='email' placeholder='E-Mail Address' />
          </FormItem>
          <FormItem>
            <Button type='primary' htmlType='submit' size='large'>Send</Button>
          </FormItem>
          <div className='forgot-password'><Link to='/login'>Remember Password?</Link></div>
        </Form>
      </div>
    </div>
  )
}
