import React from 'react'
import { Col, Row, Tabs } from 'antd'

import SystemUsers from 'modules/Users/components/SystemUsers'
import UserClients from 'modules/Users/components/UserClients'
import { userDetails, userDocuments } from 'utils/User'
import AMC from 'modules/Clients/AMC'
import SIP from 'modules/Clients/SIP'
import AboutItem from './AboutItem'
import Widget from '../Widget'

const TabPane = Tabs.TabPane

export default function About(props) {
  const { details, userId } = props
  const aboutList = userDetails(details)
  const documentList = userDocuments(details.kyc)

  return (
    <Widget title='Details' styleName='gx-card-tabs gx-card-tabs-right gx-card-profile'>
      <Tabs defaultActiveKey='1'>
        <TabPane tab='Overview' key='1'>
          <div className='gx-mb-2'>
            <Row>
              {aboutList.map((about, index) =>
                <Col key={index} xl={6} lg={12} md={12} sm={12} xs={24}>
                  <AboutItem {...about} />
                </Col>
              )}
            </Row>
          </div>
        </TabPane>
        {details.role === 'CLIENT' &&
          <TabPane tab='SIP' key='2'>
            <div className='gx-mb-2'>
              <Row>
                <SIP {...details} />
              </Row>
            </div>
          </TabPane>}
        {details.role === 'CLIENT' &&
          <TabPane tab='AMC' key='3'>
            <div className='gx-mb-2'>
              <Row>
                <AMC {...details} />
              </Row>
            </div>
          </TabPane>}
        {details.role === 'CLIENT' &&
          <TabPane tab='Documents' key='4'>
            <div className='gx-mb-2'>
              <Row>
                {documentList.map((doc, index) =>
                  <Col key={index} xl={8} lg={12} md={12} sm={12} xs={24}>
                    <AboutItem {...doc} />
                  </Col>
                )}
              </Row>
            </div>
          </TabPane>}
        {details.role !== 'CLIENT' &&
          <TabPane tab='Users' key='5'>
            <div className='gx-mb-2'>
              <Row>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <SystemUsers userId={parseInt(userId)} />
                </Col>
              </Row>
            </div>
          </TabPane>}
        {details.role !== 'CLIENT' &&
          <TabPane tab='Clients' key='6'>
            <div className='gx-mb-2'>
              <Row>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <UserClients userId={parseInt(userId)} />
                </Col>
              </Row>
            </div>
          </TabPane>}
      </Tabs>
    </Widget>
  )
}
