import gql from 'graphql-tag'

export const CREATE_USER = gql`
mutation createUser($data: UserCreateInput!){
  createUser(data: $data) {
    id
  }
}
`

export const UPDATE_USER = gql`
mutation updateUser($data: UserUpdateInput!, $id: ID!){
  updateUser(data: $data, where: { id: $id }) {
    id
  }
}
`

export const UPDATE_KYC = gql`
mutation updateKyc($panCard: Upload, $aadharCard: Upload, $passportSize: Upload, $signature: Upload, $cancelledChequeSnap: Upload, $bankStatement: Upload, $id: Int!){
  updateKyc(
    data: { panCard: $panCard, aadharCard: $aadharCard, passportSize: $passportSize, signature: $signature, cancelledChequeSnap: $cancelledChequeSnap, bankStatement: $bankStatement },
    where: { userId: $id }
  ) {
    id
  }
}
`

export const CHANGE_PASSWORD = gql`
mutation changePassword($oldPassword: String!, $newPassword: String!){
  changePassword(oldPassword: $oldPassword, newPassword: $newPassword)
}
`

export const CREATE_AMC = gql`
mutation createAmc($data: AmcCreateInput!){
  createAmc(data: $data) {
    id
  }
}
`

export const UPDATE_AMC = gql`
mutation updateAmc($data: AmcUpdateInput!, $id: ID!){
  updateAmc(data: $data, where: { id: $id }) {
    id
  }
}
`

export const CREATE_SIP = gql`
mutation createSip($data: SipCreateInput!){
  createSip(data: $data) {
    id
  }
}
`

export const UPDATE_SIP = gql`
mutation updateSip($data: SipUpdateInput!, $id: ID!){
  updateSip(data: $data, where: { id: $id }) {
    id
  }
}
`
