import domToPdf from 'dom-to-pdf'
import domToImage from 'dom-to-image'
import fileDownload from 'js-file-download'

export function exportGraphAsPdf(filename, id) {
  domToPdf(document.getElementById(id), { filename }, function () {
    console.log('PDF Generated')
  })
}

export function exportGraphAsImage(name, type, id) {
  domToImage.toBlob(document.getElementById(id))
    .then((blob) => fileDownload(blob, `${name}.${type}`))
    .catch((err) => console.log(err))
}
