import React, { useState, useEffect, useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'

import { GET_ALL_ACCESS_SETTINGS } from 'modules/Settings/graphql/Queries'
import { GET_CURRENT_USER } from './graphql/Queries'
import { AppContext } from 'AppContext'
import Callback from './Callback'
import client from 'apollo'
import * as Auth from '.'

function GuardedRoute(props) {
  const { component: Component, path } = props
  const [loading, setLoading] = useState(true)
  const isAuthenticated = Auth.isAuthenticated()
  const { dispatch } = useContext(AppContext)

  useEffect(() => {
    let userRole = ''
    if (isAuthenticated !== undefined && isAuthenticated) {
      client.query({ query: GET_CURRENT_USER, fetchPolicy: 'network-only' })
        .then((res) => {
          const { role } = res.data.currentUser
          userRole = role
          dispatch({ type: 'CURRENT_USER', data: res.data.currentUser })
          dispatch({ type: 'IS_ADMIN', data: role === 'ADMIN' })
          dispatch({ type: 'IS_STAFF', data: role === 'STAFF' })
          dispatch({ type: 'IS_MASTER_ASSOCIATE', data: role === 'MASTER_ASSOCIATE' })
          dispatch({ type: 'IS_ASSOCIATE', data: role === 'ASSOCIATE' })
          dispatch({ type: 'IS_SALES_EXECUTIVE', data: role === 'SALES_EXECUTIVE' })
          dispatch({ type: 'IS_CLIENT', data: role === 'CLIENT' })
        })
        .then(() => client.query({ query: GET_ALL_ACCESS_SETTINGS, fetchPolicy: 'network-only' }))
        .then((res) => {
          const { data: { getAllAccessSettings } } = res
          const reportData = getAllAccessSettings.filter((r) => r[userRole.toLowerCase()]).map((r) => r.title)
          dispatch({ type: 'CURRENT_REPORT', data: reportData })
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false))
    } else {
      setLoading(false)
    }
  }, [dispatch, isAuthenticated])

  return (
    loading ?
      <Route component={Callback} />
      :
      <Route
        path={path}
        render={(props) => {
          if (isAuthenticated !== undefined && !isAuthenticated) return <Redirect to='/login' />
          return <Component {...props} />
        }}
      />
  )
}

export default GuardedRoute
