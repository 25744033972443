import React, { Fragment, useState } from 'react'
import moment from 'moment'
import get from 'lodash/get'
import { NavLink } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { PageHeader, Table, Button, Input, Space, Tooltip, Modal, DatePicker } from 'antd'
import { SearchOutlined, EditTwoTone, NotificationOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'

import { CREATE_GROUP, UPDATE_GROUP, DELETE_GROUP, SEND_GROUP_NOTIFICATION } from './graphql/Mutations'
import NotificationGroupModal from './NotificationGroupModal'
import openNotification from 'utils/Notification'
import CreateGroupModal from './CreateGroupModal'
import CustomMeta from 'components/CustomMeta'
import { GET_GROUPS } from './graphql/Queries'
import Page404 from 'components/Page404'
import client from 'apollo'

const { confirm } = Modal
const { RangePicker } = DatePicker

export default function () {
  let tableData = []
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [filters, setFilters] = useState({})
  const [currentPage, setCurrentPage] = useState(1)
  const [sortingOrder, setSortingOrder] = useState('DESC')
  const [showFormModal, setShowFormModal] = useState(false)
  const [selectedGroup, setSelectedGroup] = useState(undefined)
  const [showNotificationModal, setShowNotificationModal] = useState(false)

  const variables = { where: filters, limit, skip, sort: { sortingOrder, field: 'createdAt' } }
  const { data, loading, error } = useQuery(GET_GROUPS, { variables, fetchPolicy: 'network-only' })

  if (error) return <Page404 />

  let totalCount = 0

  if (!loading && get(data, 'getGroups.groups')) {
    totalCount = data.getGroups.count
    tableData = data.getGroups.groups.map((group, key) => ({ key: key.toString(), ...group }))
  }

  function showConfirm(id) {
    confirm({
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
      title: `Do you want to delete this group?`,
      content: `When clicked the OK button, this group will be Deleted`,
      onOk() {
        client
          .mutate({ mutation: DELETE_GROUP, variables: { id }, refetchQueries: [{ query: GET_GROUPS, variables }] })
          .catch((err) => console.log(err))
      }
    })
  }

  function handleHideFormModal() {
    setShowFormModal(false)
    setSelectedGroup(undefined)
  }

  function handleHideNotificationModal() {
    setShowNotificationModal(false)
    setSelectedGroup(undefined)
  }

  function renderAction(record) {
    return <div className='action-icons'>
      <Tooltip title='Edit'>
        <EditTwoTone
          onClick={() => {
            setShowFormModal(true)
            setSelectedGroup(record)
          }} />
      </Tooltip>
      <Tooltip title='Send Notification'>
        <NotificationOutlined
          onClick={() => {
            setShowNotificationModal(true)
            setSelectedGroup(record)
          }} />
      </Tooltip>
      <Tooltip title='Delete Group'>
        <DeleteOutlined onClick={() => showConfirm(record.id)} />
      </Tooltip>
    </div>
  }

  function handleNotificationModal(values, resetForm) {
    const getUserPhoneEmails = values.sendVia === 'EMAIL' ? 'email' : 'phone'
    const userPhoneEmails = selectedGroup.users.map((user) => user[getUserPhoneEmails])
    const data = { ...values, userPhoneEmails }
    const { id } = selectedGroup
    client.mutate({ mutation: SEND_GROUP_NOTIFICATION, variables: { data, id } })
      .then((res) => {
        openNotification('success', 'Notification Sent')
        handleHideNotificationModal()
        resetForm()
      })
      .catch((err) => console.log(err))
  }

  function handleCreateGroup(values, resetForm) {
    const data = values
    client.mutate({ mutation: CREATE_GROUP, variables: { data }, refetchQueries: [{ query: GET_GROUPS, variables }] })
      .then((res) => {
        openNotification('success', 'Group Added Successfully')
        handleHideFormModal()
        resetForm()
      })
      .catch((err) => console.log(err))
  }

  function handleUpdateGroup(values, resetForm) {
    const data = values
    const { id } = selectedGroup
    client.mutate({ mutation: UPDATE_GROUP, variables: { data, id }, refetchQueries: [{ query: GET_GROUPS, variables }] })
      .then((res) => {
        openNotification('success', 'Group Updated Successfully')
        handleHideFormModal()
        resetForm()
      })
      .catch((err) => console.log(err))
  }

  function handlePagination(page) {
    setSkip((page - 1) * limit)
    setCurrentPage(page)
  }

  function handlePageSizeChange(current, size) {
    setLimit(size)
  }

  function handleSearch(value, confirm, filedName) {
    value = typeof value === 'object' ? value[0] : value
    confirm()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    tempFilters[filedName] = value
    setFilters(tempFilters)
    setCurrentPage(1)
  }

  function handleReset(clearFilters, filedName) {
    clearFilters()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    if (tempFilters[filedName]) {
      delete tempFilters[filedName]
    }
    setFilters(tempFilters)
    setCurrentPage(1)
  }

  function handleTableChange(pagination, filter, sorter) {
    const tempFilters = JSON.parse(JSON.stringify(filters))
    if (filter.status) {
      tempFilters['status'] = filter.status
      setFilters(tempFilters)
    } else if (sorter && sorter.field && sorter.order) {
      if (sorter.order === 'descend') {
        setSortingOrder('DESC')
      } else {
        setSortingOrder('ASC')
      }
    } else {
      tempFilters['status'] = ''
      delete tempFilters.status
      setFilters(tempFilters)
    }
    setCurrentPage(1)
  }

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys[0], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters, dataIndex)} size='small' style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
  })

  const getColumnDateProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <RangePicker
          onChange={e => {
            let tempFilters
            if (e) {
              confirm()
              tempFilters = JSON.parse(JSON.stringify(filters))
              tempFilters['from'] = e[0]
              tempFilters['to'] = e[1]
            } else {
              clearFilters()
              tempFilters = JSON.parse(JSON.stringify(filters))
              delete tempFilters.from
              delete tempFilters.to
            }
            setFilters(tempFilters)
            setCurrentPage(1)
          }}
        />
      </div>
    ),
  })

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => <NavLink to={`/groups/${record.id}`}>{text}</NavLink>,
      ...getColumnSearchProps('name'),
      width: '50%',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: date => moment(date).format('Do MMMM YYYY'),
      sorter: (a, b) => a.name.length - b.name.length,
      width: '40%',
      ...getColumnDateProps('createdAt')
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => renderAction(record),
      width: '10%',
    }
  ]

  return (
    <Fragment>
      <CustomMeta title='Groups' />
      <PageHeader
        className='box'
        title='Groups'
        extra={[
          <Button
            key='1'
            type='primary'
            onClick={() => setShowFormModal(true)}
          >
            Add group
          </Button>
        ]}
      />
      {(showNotificationModal && selectedGroup) &&
        <NotificationGroupModal
          visible={showNotificationModal}
          onSend={handleNotificationModal}
          onCancel={() => handleHideNotificationModal()}
        />
      }
      {showFormModal &&
        <CreateGroupModal
          visible={showFormModal}
          onCreate={handleCreateGroup}
          onUpdate={handleUpdateGroup}
          selectedGroup={selectedGroup}
          isEdit={selectedGroup !== undefined}
          onCancel={() => handleHideFormModal()}
        />
      }
      <Table
        loading={loading}
        columns={columns}
        scroll={{ x: true }}
        dataSource={tableData}
        onChange={handleTableChange}
        pagination={{ pageSize: limit, total: totalCount, onChange: handlePagination, current: currentPage, onShowSizeChange: handlePageSizeChange }}
      />
    </Fragment>
  )
}
