import React from 'react'
import { get } from 'lodash'
import { Button } from 'antd'
import { useQuery } from '@apollo/react-hooks'

import NoData from 'components/NoData'
import Page404 from 'components/Page404'
import UnhedgeTable from './UnhedgeTable'
import { exportGraphAsPdf } from 'utils/Graph'
import Loader from 'components/loaders/Loader'
import { GET_UNHEDGE_REPORT } from '../graphql/Queries'
import '../index.css'

export default function () {
  const { data, loading, error } = useQuery(GET_UNHEDGE_REPORT, { variables: { where: { ii: 0, strategy: 'ILTS' } } })

  if (loading) return <Loader />
  if (error) return <Page404 />
  if (!get(data, 'spUnhedgeReportInput')) return <NoData />

  const { spUnhedgeReportInput: [spUnhedgeReportInput] } = data

  function generatePdf() {
    exportGraphAsPdf('UnHedge-report.pdf', 'UNHEDGE-REPORT-PDF')
  }

  return (
    <>
      <Button className='download-button' key='2' type='primary' size='small' onClick={() => generatePdf()}>Download</Button>
      <div className='report-table-wrapper' id='UNHEDGE-REPORT-PDF'>
        <UnhedgeTable reportData={spUnhedgeReportInput.filter((report, key) => key !== 0)} />
      </div>
    </>
  )
}
