import React from 'react'
import get from 'lodash/get'
import { Button } from 'antd'
import { useQuery } from '@apollo/react-hooks'

import NoData from 'components/NoData'
import Page404 from 'components/Page404'
import { exportGraphAsPdf } from 'utils/Graph'
import Loader from 'components/loaders/Loader'
import { GET_MONTHLY_EXPOSURE_REPORT } from 'modules/Reports/graphql/Queries'
import MonthlyExposureTable from 'modules/Reports/MonthlyExposure/MonthlyExposureTable'
import 'modules/Reports/index.css'

export default function (props) {
  let { match: { params: { clientCode, pnl } } } = props
  const { data, loading, error } = useQuery(GET_MONTHLY_EXPOSURE_REPORT, { variables: { where: { clientCode, strategy: 'ILTS', ii: pnl ? parseInt(pnl) : undefined } } })

  function generatePdf() {
    exportGraphAsPdf('monthly-exposure.pdf', 'MONTHLY-EXPOSURE-REPORT-PDF')
  }

  if (loading) return <Loader />
  if (error) return <Page404 />
  if (!get(data, 'spMonthlyExposureReport')) return <NoData />

  const { spMonthlyExposureReport: [spMonthlyExposureReport] } = data

  return (
    <>
      <Button className='download-button' key='2' type='primary' size='small' onClick={() => generatePdf()}>Download</Button>
      <div className='report-table-wrapper' id='MONTHLY-EXPOSURE-REPORT-PDF'>
        <MonthlyExposureTable reportData={spMonthlyExposureReport} />
      </div>
    </>
  )
}
