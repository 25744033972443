import React, { Component } from 'react'
import { Router, Switch, Route } from 'react-router-dom'

import ForgotPassword from 'auth/ForgotPassword'
import ResetPassword from 'auth/ResetPassword'
import GuardedRoute from 'auth/GuardedRoute'
import history from 'CustomHistory'
import Logout from 'auth/Logout'
import Login from 'auth/Login'
import Layout from 'layout'

export default class Routes extends Component {
  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route exact path='/login' component={Login} />
          <Route exact path='/logout' component={Logout} />
          <Route exact path='/forgot-password' component={ForgotPassword} />
          <Route exact path='/reset-password/:token' component={ResetPassword} />
          <GuardedRoute path='/' name='layout' component={Layout} />
        </Switch>
      </Router>
    )
  }
}
