import React, { Fragment, useState, useEffect, useContext } from 'react'
import { Form, Input, Tooltip, Row, Col, Button, DatePicker, Select, Upload, InputNumber } from 'antd'
import { QuestionCircleOutlined, UploadOutlined } from '@ant-design/icons'
import get from 'lodash/get'
import moment from 'moment'

import { GET_USERS } from 'modules/Users/graphql/Queries' // GET_LEAD_USERS
import countries from 'utils/countries.json'
import { AppContext } from 'AppContext'
import states from 'utils/states.json'
import cities from 'utils/cities.json'
import { getName } from 'utils/User'
import client from 'apollo'
import '../clients.css'

const { Option } = Select
const defaultSteps = [
  { key: 'KYC', value: 'KYC at Finideas end' },
  { key: 'DOCUMENT_COLLECTION', value: 'Documents collection' },
  { key: 'MEMBER_FORM_SUBMIT', value: 'Member end form submit and KYC' },
  { key: 'TERM_MAIL', value: 'Terms and Condition E-Mail' },
  { key: 'CHEQUE_COLLECTION', value: 'Cheque Collection (Fund)' },
  { key: 'AMC_CHEQUE_COLLECTION', value: 'AMC Cheque Collection' },
  { key: 'ACCOUNT_MAPPING', value: 'Account mapping at member end' },
  { key: 'ELIGIBILITY_FOR_TRADING', value: 'Eligibility for trading' },
  { key: 'DONE', value: 'Profile Completed' }
]

const formItemLayout = {
  labelCol: { xs: { span: 24 }, sm: { span: 24 } },
  wrapperCol: { xs: { span: 24 }, sm: { span: 24 } }
}

const tailFormItemLayout = {
  wrapperCol: { xs: { span: 24, offset: 0 }, sm: { span: 16, offset: 8 } }
}

function disabledDate(current) {
  return current && current > moment().endOf('day')
}

const ClientForm = (props) => {
  const { state: { isStaff, isAdmin } } = useContext(AppContext) // currentUser
  const { onFinish, initialValues, loading, isUpdate } = props
  const [selectedState, setSelectedState] = useState(isUpdate && initialValues.stateId ? states.filter((state) => state.id === initialValues.stateId)[0].id : undefined)
  const [selectedCountry, setSelectedCountry] = useState(isUpdate && initialValues.countryId ? initialValues.countryId : undefined)
  const [fileList, setFileList] = useState(undefined)
  const [users, setUsers] = useState(undefined)
  // const [leadUsers, setLeadUsers] = useState(undefined)
  const [form] = Form.useForm()

  useEffect(() => {
    if (isAdmin || isStaff) {
      client.query({ query: GET_USERS, variables: { limit: 300, where: { role: ['MASTER_ASSOCIATE', 'ASSOCIATE', 'SALES_EXECUTIVE'] } }, fetchPolicy: 'network-only' })
        .then((res) => {
          if (get(res, 'data.getUsers.users')) {
            setUsers(get(res, 'data.getUsers.users'))
          }
        })
        .catch((err) => console.log(err))
    }
  }, [isAdmin, isStaff])

  // useEffect(() => {
  //   if (!isStaff && !isAdmin && !isUpdate) {
  //     client.query({ query: GET_LEAD_USERS, variables: { userId: parseInt(currentUser.id) }, fetchPolicy: 'network-only' })
  //       .then((res) => {
  //         if (get(res, 'data.getLeadUsers')) {
  //           setLeadUsers(get(res, 'data.getLeadUsers'))
  //         }
  //       })
  //       .catch((err) => console.log(err))
  //   }
  // }, [currentUser, isAdmin, isStaff, isUpdate])

  // function getLeadUsers(userId) {
  //   client.query({ query: GET_LEAD_USERS, variables: { userId }, fetchPolicy: 'network-only' })
  //     .then((res) => {
  //       if (get(res, 'data.getLeadUsers')) {
  //         setLeadUsers(get(res, 'data.getLeadUsers'))
  //       } else {
  //         setLeadUsers(undefined)
  //       }
  //     })
  //     .catch((err) => setLeadUsers(undefined))
  // }

  const normFile = e => {
    setFileList([])
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  const customProps = {
    multiple: false,
    onRemove: file => setFileList([]),
    beforeUpload: file => {
      setFileList(file)
      return false
    },
    fileList
  }

  return (
    <Form
      {...formItemLayout}
      form={form}
      name='register'
      scrollToFirstError
      onFinish={onFinish}
      className='client-form'
      initialValues={initialValues || {}}
    // initialValues={{
    //   firstName: 'parth',
    //   lastName: 'patel',
    //   userName: 'parth',
    //   email: 'patel21parth@gmail.com',
    //   phone: '9033174273',
    //   dateOfBirth: moment(),
    //   password: '0123456789',
    //   confirm: '0123456789',
    //   motherName: 'motherName',
    //   fatherName: 'fatherName',
    //   address: 'address',
    //   clientCode: 'STR015',
    //   strategyCode: 'ILTS',
    //   amcAmount: 5000,
    //   amcStartDate: moment(),
    //   sipAmount: 5000,
    //   sipStartDate: moment(),
    //   initialExposure: 500,
    //   exposure: 500,
    //   closedDate: moment(),
    //   createdBy: moment(),
    // }}
    >
      <Row gutter={16}>
        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='firstName'
            label='First Name'
            rules={[{ required: true, message: 'Please input your First Name!' }]}
          >
            <Input placeholder='Enter First Name' />
          </Form.Item>
        </Col>
        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='lastName'
            label='Last Name'
            rules={[{ required: true, message: 'Please input your Last Name!' }]}
          >
            <Input placeholder='Enter Last Name' />
          </Form.Item>
        </Col>

        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='userName'
            autoComplete='new-password'
            rules={[{ required: true, message: 'Please input your valid username!', pattern: new RegExp(/^[a-zA-Z0-9]+$/g) }]}
            label={<span>Username&nbsp;<Tooltip title='Choose your unique username for login.'><QuestionCircleOutlined /></Tooltip></span>}
          >
            <Input autoComplete='new-password' placeholder='Enter Username' disabled={isUpdate} />
          </Form.Item>
        </Col>

        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='email'
            label='E-mail'
            rules={[
              { required: true, message: 'Please input your E-mail!' },
              { message: 'The input is not valid E-mail!', pattern: new RegExp(/^[a-z0-9._-]+@[a-z0-9.-]+\.[a-z]{2,4}$/) }
            ]}
          >
            <Input placeholder='Enter Your Email' />
          </Form.Item>
        </Col>

        {
          !isUpdate &&
          <Fragment>
            <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                hasFeedback
                name='password'
                label='Password'
                autoComplete='new-password'
                rules={[{ required: true, message: 'Please input your password between 8 to 16 characters!', min: 8, max: 16 }]}
              >
                <Input.Password placeholder='Enter Your Password' autoComplete='new-password' />
              </Form.Item>
            </Col>

            <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                hasFeedback
                name='confirm'
                label='Confirm Password'
                dependencies={['password']}
                rules={[
                  { required: true, message: 'Please confirm your password!' },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve()
                      }
                      return Promise.reject('The two passwords that you entered do not match!')
                    },
                  }),
                ]}
              >
                <Input.Password placeholder='Enter Confirm Password' />
              </Form.Item>
            </Col>
          </Fragment>
        }

        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='phone'
            label='Phone Number'
            rules={[{ required: true, message: 'Please input your 10 digit phone number!', min: 10, max: 10 }]}
          >
            <Input autoComplete='off' type='number' style={{ width: '100%' }} placeholder='Enter Phone number' />
          </Form.Item>
        </Col>

        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='dateOfBirth'
            label='Date of Birth'
            rules={[{ required: true, message: 'Please input your Date of Birth!' }]}
          >
            <DatePicker autoComplete='off' disabledDate={disabledDate} showToday={false} style={{ width: '100%' }} />
          </Form.Item>
        </Col>

        {/* <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='folderPath'
            label='Folder Path'
            rules={[{ required: true, message: 'Please input your Folder Path!', whitespace: true }]}
          >
            <Input />
          </Form.Item>
        </Col> */}

        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='motherName'
            label='Mother Name'
            rules={[{ required: true, message: 'Please input your Mother Name!' }]}
          >
            <Input placeholder={`Enter Mother's Name`} />
          </Form.Item>
        </Col>
        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='fatherName'
            label='Father Name'
            rules={[{ required: true, message: 'Please input your Father Name!' }]}
          >
            <Input placeholder={`Enter Father's Name`} />
          </Form.Item>
        </Col>

        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='address'
            label='Address'
            rules={[{ required: true, message: 'Please enter your Address!' }]}
          >
            <Input placeholder='Enter Address' />
          </Form.Item>
        </Col>

        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='countryId'
            label='Select your country'
            rules={[{ required: true, message: 'Please Select your country!' }]}
          >
            <Select
              showSearch
              optionFilterProp='children'
              autoComplete='something-new'
              placeholder='Select your country'
              onChange={(value) => {
                setSelectedCountry(value)
                form.setFieldsValue({
                  stateId: undefined,
                  cityId: undefined,
                })
              }}
            >
              {countries.map((country) => <Option key={country.id} value={country.id}>{country.name}</Option>)}
            </Select>
          </Form.Item>
        </Col>

        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='stateId'
            label='Select your state'
            rules={[{ required: true, message: 'Please Select your state!' }]}
          >
            <Select
              showSearch
              optionFilterProp='children'
              autoComplete='something-new'
              placeholder='Select your state'
              notFoundContent='Please Select Your Country First!'
              onChange={(value) => {
                setSelectedState(states.filter((state) => state.id === value)[0].id)
                form.setFieldsValue({
                  cityId: undefined,
                })
              }}
            >
              {!!selectedCountry && states.map((state) => <Option key={state.id} value={state.id}>{state.name}</Option>)}
            </Select>
          </Form.Item>
        </Col>

        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='cityId'
            label='Select your city'
            rules={[{ required: true, message: 'Please Select your city!' }]}
          >
            <Select
              showSearch
              optionFilterProp='children'
              autoComplete='something-new'
              placeholder='Select your city'
              notFoundContent='Please Select Your State First!'
            >
              {!!selectedState && cities.filter((city) => city.state_id === selectedState).map((city) => <Option key={city.id} value={city.id}>{city.name}</Option>)}
            </Select>
          </Form.Item>
        </Col>

        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='clientCode'
            label='Client Code'
            rules={[{ required: true, message: 'Please input your Client Code!' }]}
          >
            <Input style={{ width: '100%' }} placeholder='Enter Client Code' />
          </Form.Item>
        </Col>

        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='strategyCode'
            label='Strategy Code'
            rules={[{ required: true, message: 'Please input your Strategy Code!' }]}
          >
            <Input style={{ width: '100%' }} placeholder='Enter Strategy Code' />
          </Form.Item>
        </Col>

        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='amcAmount'
            label='AMC Amount'
            rules={[{ required: true, message: 'Please input your AMC Amount!' }]}
          >
            <InputNumber style={{ width: '100%' }} placeholder='Enter AMC Amount' />
          </Form.Item>
        </Col>

        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='amcStartDate'
            label='AMC Start Date'
            rules={[{ required: true, message: 'Please input your AMC Start Date!' }]}
          >
            <DatePicker autoComplete='off' style={{ width: '100%' }} />
          </Form.Item>
        </Col>

        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='sipAmount'
            label='SIP Amount'
          // rules={[{ required: true, message: 'Please input your SIP Amount!' }]}
          >
            <InputNumber style={{ width: '100%' }} placeholder='Enter SIP Amount' />
          </Form.Item>
        </Col>

        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='sipStartDate'
            label='SIP Start Date'
          // rules={[{ required: true, message: 'Please input your SIP Start Date!' }]}
          >
            <DatePicker autoComplete='off' style={{ width: '100%' }} />
          </Form.Item>
        </Col>

        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='initialExposure'
            label='Initial Exposure'
            rules={[{ required: true, message: 'Please input your Initial Exposure!' }]}
          >
            <InputNumber style={{ width: '100%' }} placeholder='Enter Initial Exposure' />
          </Form.Item>
        </Col>

        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='exposure'
            label='Exposure'
            rules={[{ required: true, message: 'Please input your Exposure!' }]}
          >
            <InputNumber style={{ width: '100%' }} placeholder='Enter Exposure' />
          </Form.Item>
        </Col>

        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='completedSteps'
            label='User Completed Step'
          >
            <Select placeholder='User Completed Step'>
              {defaultSteps.map((defaultStep) => <Option key={defaultStep.key} value={defaultStep.key}>{defaultStep.value}</Option>)}
            </Select>
          </Form.Item>
        </Col>

        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='closedDate'
            label='Closed Date'
          // rules={[{ required: true, message: 'Please input your Closed Date!' }]}
          >
            <DatePicker autoComplete='off' style={{ width: '100%' }} />
          </Form.Item>
        </Col>

        {
          users &&
          <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name='createdById'
              label='Select User'
              rules={[{ required: true, message: 'Please Select User!' }]}
            >
              <Select
                showSearch
                optionFilterProp='children'
                autoComplete='something-new'
                placeholder='Select user'
              >
                {users.map((user) => <Option key={user.id} value={user.id}>{getName(user)}</Option>)}
              </Select>
            </Form.Item>
          </Col>
        }

        {
          !isUpdate &&
          <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name='createdAt'
              label='Created Date'
            >
              <DatePicker autoComplete='off' style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        }

        {
          !isUpdate && !users &&
          <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}></Col>
        }

        {/* {
          leadUsers &&
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Row
              style={{
                border: '1px solid #003365',
                borderRadius: '10px',
                padding: '24px 0',
                margin: '24px 0',
              }}
            >
              {
                leadUsers.map((leadUser) => {
                  return (
                    <>
                      <Col className='gutter-row' xs={24} sm={24} md={8} lg={8} xl={8}>
                        <Form.Item
                          name='names'
                          label='Name'
                        >
                          <Input autoComplete='off' disabled defaultValue={getName(leadUser)} />
                        </Form.Item>
                      </Col>
                      <Col className='gutter-row' xs={24} sm={24} md={8} lg={8} xl={8}>
                        <Form.Item
                          name='userNames'
                          label='UserName'
                        >
                          <Input autoComplete='off' disabled defaultValue={getName(leadUser)} />
                        </Form.Item>
                      </Col>
                      <Col className='gutter-row' xs={24} sm={24} md={8} lg={8} xl={8}>
                        <Form.Item
                          name={`ratio_${leadUser.id}`}
                          label='Ratio'
                          rules={[{ required: true, message: 'Please input your ratio!' }]}
                        >
                          <InputNumber
                            min={0}
                            max={100}
                            placeholder='Enter Ratio'
                            style={{ width: '100%' }}
                            parser={value => value.replace('%', '')}
                            formatter={value => value ? `${value}%` : ''}
                          />
                        </Form.Item>
                      </Col>
                    </>
                  )
                })
              }
            </Row>
          </Col>
        } */}


        {/* <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='createdBy'
            label='Assign to'
            rules={[{ required: true, message: 'Please Select Assignee!' }]}
          >
            <Select
              showSearch
              optionFilterProp='children'
              autoComplete='something-new'
              placeholder='Assign to'
            >
              {!!users && users.map((user) => <Option key={user.id} value={user.id}>{getName(user)}</Option>)}
              <Option value="lucy">Lucy</Option>
            </Select>
          </Form.Item>
        </Col> */}

        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='panCard'
            label='PanCard'
            valuePropName='fileList'
            getValueFromEvent={normFile}
          >
            <Upload name='panCard-file' listType='text' {...customProps} accept='.jpeg,.png,.jpg,.pdf,.docx,.doc'>
              <Button><UploadOutlined /> Click to upload</Button>
            </Upload>
          </Form.Item>
        </Col>

        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='signature'
            label='Signature'
            valuePropName='fileList'
            getValueFromEvent={normFile}
          >
            <Upload name='signature-file' listType='text' {...customProps} accept='.jpeg,.png,.jpg,.pdf,.docx,.doc'>
              <Button><UploadOutlined /> Click to upload</Button>
            </Upload>
          </Form.Item>
        </Col>

        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='aadharCard'
            label='Aadhar Card'
            valuePropName='fileList'
            getValueFromEvent={normFile}
          >
            <Upload name='aadharCard-file' listType='text' {...customProps} accept='.jpeg,.png,.jpg,.pdf,.docx,.doc'>
              <Button><UploadOutlined /> Click to upload</Button>
            </Upload>
          </Form.Item>
        </Col>

        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='passportSize'
            label='Passport Size Photo'
            valuePropName='fileList'
            getValueFromEvent={normFile}
          >
            <Upload name='passportSize-file' listType='text' {...customProps} accept='.jpeg,.png,.jpg,.pdf,.docx,.doc'>
              <Button><UploadOutlined /> Click to upload</Button>
            </Upload>
          </Form.Item>
        </Col>

        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='bankStatement'
            label='Bank Statement'
            valuePropName='fileList'
            getValueFromEvent={normFile}
          >
            <Upload name='bankStatement-file' listType='text' {...customProps} accept='.jpeg,.png,.jpg,.pdf,.docx,.doc'>
              <Button><UploadOutlined /> Click to upload</Button>
            </Upload>
          </Form.Item>
        </Col>

        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='cancelledChequeSnap'
            label='Cancelled Cheque'
            valuePropName='fileList'
            getValueFromEvent={normFile}
          >
            <Upload name='cancelledChequeSnap-file' listType='text' {...customProps} accept='.jpeg,.png,.jpg,.pdf,.docx,.doc'>
              <Button><UploadOutlined /> Click to upload</Button>
            </Upload>
          </Form.Item>
        </Col>

        <Col className='gutter-row' span={24}>
          <Form.Item {...tailFormItemLayout}>
            <Button type='primary' htmlType='submit' loading={loading}>Save</Button>
          </Form.Item>
        </Col>
      </Row>
    </Form >
  )
}

export default ClientForm
