import gql from 'graphql-tag'

export const GET_MESSAGES = gql`
query getAllMessages{
  getAllMessages {
    id
    title
    type
    description
    createdAt
    updatedAt
  }
}`
