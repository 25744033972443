import React, { useContext, Fragment } from 'react'
import {
  TagOutlined,
  UserOutlined,
  // WalletOutlined,
  MessageOutlined,
  SettingOutlined,
  PieChartOutlined,
  CalendarOutlined,
  ApartmentOutlined,
  DashboardOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons'
import { NavLink } from 'react-router-dom'
import { Menu } from 'antd'

import { AppContext } from 'AppContext'

const { SubMenu } = Menu

export default function ({ pathname }) {
  const { state: { isAdmin, isStaff, isClient, isAssociate, currentReport } } = useContext(AppContext)
  let selectedItem = pathname.split('/')[1]
  if (selectedItem === 'reports') {
    const splitArray = pathname.split('/')
    selectedItem = splitArray[splitArray.length - 1]
  }

  return (
    <Menu theme='dark' mode='inline' selectedKeys={[selectedItem || 'dashboard']}>
      <Menu.Item key='dashboard'>
        <NavLink to='/' className='nav-text'>
          <DashboardOutlined />
          <span>Dashboard</span>
        </NavLink>
      </Menu.Item>
      {!(isClient || isAssociate) && <Menu.Item key='users'>
        <NavLink to='/users' className='nav-text'>
          <UserOutlined />
          <span>Users</span>
        </NavLink>
      </Menu.Item>}
      {!isClient && <Menu.Item key='clients'>
        <NavLink to='/clients' className='nav-text'>
          <UsergroupAddOutlined />
          <span>Clients</span>
        </NavLink>
      </Menu.Item>}
      {!isClient && <Menu.Item key='groups'>
        <NavLink to='/groups' className='nav-text'>
          <ApartmentOutlined />
          <span>Groups</span>
        </NavLink>
      </Menu.Item>}
      {/* {!isClient && !isAdmin && <Menu.Item key='revenue-income'>
        <NavLink to='/revenue-income' className='nav-text'>
          <WalletOutlined />
          <span>Revenue Income</span>
        </NavLink>
      </Menu.Item>} */}
      <Menu.Item key='amc'>
        <NavLink to='/amc' className='nav-text'>
          <CalendarOutlined />
          <span>AMC</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key='sip'>
        <NavLink to='/sip' className='nav-text'>
          <CalendarOutlined />
          <span>SIP</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key='tickets'>
        <NavLink to='/tickets' className='nav-text'>
          <TagOutlined />
          <span>Tickets</span>
        </NavLink>
      </Menu.Item>
      <SubMenu key='reports' className='custom-sub-menu' title={<Fragment><PieChartOutlined /> <span>Reports</span></Fragment>}>
        {isClient && currentReport.includes('ILTS') && <Menu.Item key='performance'><NavLink to='/reports/performance' className='nav-text'>Performance</NavLink></Menu.Item>}
        {!isClient && <Menu.Item key='ledger'><NavLink to='/reports/ledger' className='nav-text'>Ledger</NavLink></Menu.Item>}
        {isClient && currentReport.includes('Interest') && <Menu.Item key='interest'><NavLink to='/reports/interest' className='nav-text'>Interest</NavLink></Menu.Item>}
        {isClient && currentReport.includes('Unhedge') && <Menu.Item key='unhedge'><NavLink to='/reports/unhedge' className='nav-text'>Unhedge</NavLink></Menu.Item>}
        {!isClient && <Menu.Item key='trade-eq'><NavLink to='/reports/trade-eq' className='nav-text'>Trade EQ</NavLink></Menu.Item>}
        {!isClient && <Menu.Item key='trade-fo'><NavLink to='/reports/trade-fo' className='nav-text'>Trade FO</NavLink></Menu.Item>}
        {isClient && currentReport.includes('Performance') && <Menu.Item key='performance'><NavLink to='/reports/performance' className='nav-text'>Cost To Profit</NavLink></Menu.Item>}
        {isClient && currentReport.includes('Average Index') && <Menu.Item key='average-index'><NavLink to='/reports/average-index' className='nav-text'>Average Index</NavLink></Menu.Item>}
        {isClient && currentReport.includes('NLV Mismatch') && <Menu.Item key='nlv-mismatch'><NavLink to='/reports/nlv-mismatch' className='nav-text'>NLV Mismatch</NavLink></Menu.Item>}
        {isClient && currentReport.includes('Current Position') && <Menu.Item key='current-position'><NavLink to='/reports/current-position' className='nav-text'>Current Position</NavLink></Menu.Item>}
        {isClient && currentReport.includes('NLV Tracker') && <Menu.Item key='nlv-tracker'><NavLink to='/reports/comparison-of-fund' className='nav-text'>Comparison of Fund</NavLink></Menu.Item>}
      </SubMenu>
      {(isAdmin || isStaff) && <Menu.Item key='messages'>
        <NavLink to='/messages' className='nav-text'>
          <MessageOutlined />
          <span>Messages</span>
        </NavLink>
      </Menu.Item>}
      {(isAdmin || isStaff) && <Menu.Item key='settings'>
        <NavLink to='/settings' className='nav-text'>
          <SettingOutlined />
          <span>Settings</span>
        </NavLink>
      </Menu.Item>}
    </Menu>
  )
}
