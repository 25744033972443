import React from 'react'
import ReactDOM from 'react-dom'
// import * as Sentry from '@sentry/browser'
import { ApolloProvider } from '@apollo/react-hooks'
import { AppContextProvider } from 'AppContext'
import * as serviceWorker from 'serviceWorker'
import { version } from '../package.json'
import client from 'apollo'
import App from 'App'

// Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN, environment: process.env.REACT_APP_NODE_ENV })

console.log(`Version: ${version}`)

ReactDOM.render(
  <ApolloProvider client={client}>
    <AppContextProvider>
      <App />
    </AppContextProvider>
  </ApolloProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
