import React, { Fragment, useState } from 'react'
import get from 'lodash/get'
import omit from 'lodash/omit'
import pick from 'lodash/pick'
import { PageHeader } from 'antd'
import isEmpty from 'lodash/isEmpty'

import client from 'apollo'
import ClientForm from './components/ClientForm'
import openNotification from 'utils/Notification'
import { CREATE_USER, UPDATE_KYC } from '../Users/graphql/Mutations'

function getFilesObject(tempFiles) {
  let files = pick(tempFiles, ['panCard', 'signature', 'aadharCard', 'passportSize', 'bankStatement', 'cancelledChequeSnap'])
  if (get(files, 'panCard[0].originFileObj')) {
    files.panCard = get(files, 'panCard[0].originFileObj')
  } else {
    delete files.panCard
  }
  if (get(files, 'signature[0].originFileObj')) {
    files.signature = get(files, 'signature[0].originFileObj')
  } else {
    delete files.signature
  }
  if (get(files, 'aadharCard[0].originFileObj')) {
    files.aadharCard = get(files, 'aadharCard[0].originFileObj')
  } else {
    delete files.aadharCard
  }
  if (get(files, 'passportSize[0].originFileObj')) {
    files.passportSize = get(files, 'passportSize[0].originFileObj')
  } else {
    delete files.passportSize
  }
  if (get(files, 'bankStatement[0].originFileObj')) {
    files.bankStatement = get(files, 'bankStatement[0].originFileObj')
  } else {
    delete files.bankStatement
  }
  if (get(files, 'cancelledChequeSnap[0].originFileObj')) {
    files.cancelledChequeSnap = get(files, 'cancelledChequeSnap[0].originFileObj')
  } else {
    delete files.cancelledChequeSnap
  }
  return files
}

function getNewValues(values) {
  let users = []
  Object.keys(values).forEach((value, key) => {
    if (values[key] === 'names' || values[key] === 'userNames') {
      delete values[key]
    }
    if (values[value] && typeof values[value] === 'number' && value.startsWith('ratio_')) {
      users.push({ userId: value.replace('ratio_', ''), ratio: values[value] })
      delete values[value]
    }
  })
  values.ratio = JSON.stringify(users)
  return values
}

export default function ({ history }) {
  const [loading, setLoading] = useState()

  const onFinish = values => {
    setLoading(true)
    let data = omit(values, ['confirm', 'panCard', 'signature', 'aadharCard', 'passportSize', 'bankStatement', 'cancelledChequeSnap'])
    data = getNewValues(data)
    const queryVariables = { data }

    client.mutate({ mutation: CREATE_USER, variables: queryVariables })
      .then((res) => {
        const userId = res.data.createUser.id
        const files = getFilesObject(values)
        if (isEmpty(files)) {
          openNotification('success', 'Client Created Successfully')
          history.push(`/clients/${userId}`)
          setLoading(false)
        } else {
          client.mutate({ mutation: UPDATE_KYC, variables: { id: parseInt(userId), ...files } })
            .then((res) => {
              history.replace(`/clients/${userId}`)
              openNotification('success', 'Client Created Successfully')
            })
            .catch((err) => console.log(err))
            .finally(() => setLoading(false))
        }
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }

  return (
    <Fragment>
      <PageHeader
        className='box'
        onBack={() => history.goBack()}
        title='Add New Client'
      />
      <ClientForm onFinish={onFinish} loading={loading} />
    </Fragment>
  )
}
