import React, { Fragment, useState, useContext } from 'react'
import { PageHeader, Table, Tooltip, Popconfirm, Button, Switch, Input, Space } from 'antd'
import { EditTwoTone, PlusCircleOutlined, SearchOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/react-hooks'
import { NavLink } from 'react-router-dom'
import get from 'lodash/get'
import moment from 'moment'

import client from 'apollo'
import { AppContext } from 'AppContext'
import Page404 from 'components/Page404'
import CreateSIPModal from 'modules/SIP/Modal'
import CreateAMCModal from 'modules/AMC/Modal'
import CustomMeta from 'components/CustomMeta'
import openNotification from 'utils/Notification'
import { GET_USERS } from '../Users/graphql/Queries'
import { getFirstName, getLastName } from 'utils/User'
import { UPDATE_USER } from '../Users/graphql/Mutations'
import { CREATE_SIP } from 'modules/Users/graphql/Mutations'
import { CREATE_AMC } from 'modules/Users/graphql/Mutations'

const months = ['All', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

export default function ({ history }) {
  let tableData = []
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const { state } = useContext(AppContext)
  const [currentPage, setCurrentPage] = useState(1)
  const [switchLoading, setSwitchLoading] = useState('')
  const [clientData, setClientData] = useState(undefined)
  const [showFormModal, setShowFormModal] = useState(false)
  const [filters, setFilters] = useState({ role: 'CLIENT' })
  const [showFormLoading, setShowFormLoading] = useState(false)
  const [showAMCFormModal, setAMCShowFormModal] = useState(false)
  const { data, loading, error } = useQuery(GET_USERS, { variables: { where: filters, limit, skip }, fetchPolicy: 'network-only' })

  if (error) return <Page404 />

  let totalCount = 0
  if (!loading && get(data, 'getUsers.users')) {
    totalCount = data.getUsers.count
    tableData = data.getUsers.users.map((user, key) => ({ key: key.toString(), ...user }))
  }

  function handleSwitch(record) {
    setSwitchLoading(record.id)
    const queryVariables = { id: record.id, data: { isActive: !record.isActive } }
    client.mutate({ mutation: UPDATE_USER, variables: queryVariables, refetchQueries: [{ query: GET_USERS, variables: { where: filters, limit, skip } }] })
      .then((res) => openNotification('success', 'Client Updated Successfully'))
      .catch((err) => console.log(err))
      .finally(() => setSwitchLoading(''))
  }

  async function handleSIPCreate(values, resetForm) {
    if (values.multiple) {
      const { whichMonthFrom, whichMonthTo } = values
      const dateMoment = moment(values.date)
      const total = (months.findIndex((m) => m === whichMonthTo) - months.findIndex((m) => m === whichMonthFrom)) + 1
      const monthsCount = []
      for (let index = 1; index <= total; index++) {
        monthsCount.push(months[index])
      }
      delete values.date
      delete values.multiple
      delete values.whichMonthTo
      delete values.whichMonthFrom
      try {
        for (const monthCount of monthsCount) {
          const date = `${moment().format('YYYY')}-${months.findIndex((m) => m === monthCount)}-${dateMoment.format('D')}`
          const dataValues = { ...values, whichMonth: monthCount }
          dataValues.startDate = moment(new Date(date)).add(1, 'days');
          dataValues.endDate = moment(new Date(date)).add(1, 'months');
          await client.mutate({
            mutation: CREATE_SIP,
            variables: { data: { ...dataValues, clientId: parseInt(clientData.id) } },
          })
            .catch((err) => console.log(err))
        }
        openNotification('success', 'SIPs Created Successfully')
        setShowFormLoading(false)
        setShowFormModal(false)
        resetForm()
      } catch (e) {
        console.log(e)
        setShowFormLoading(false)
      }
    } else {
      delete values.multiple
      const dateMoment = moment(values.date)
      const date = `${moment().format('YYYY')}-${months.findIndex((m) => m === values.whichMonth)}-${dateMoment.format('D')}`
      values.startDate = moment(new Date(date)).add(1, 'days');
      values.endDate = moment(new Date(date)).add(1, 'months');
      delete values.date
      setShowFormLoading(true)
      client.mutate({
        mutation: CREATE_SIP,
        variables: { data: { ...values, clientId: parseInt(clientData.id) } },
      })
        .then((res) => {
          openNotification('success', 'SIP Created Successfully')
          setShowFormModal(false)
          resetForm()
        })
        .catch((err) => console.log(err))
        .finally(() => setShowFormLoading(false))
    }
  }

  function handleAMCCreate(values, resetForm) {
    const dateMoment = moment(values.date)
    const date = `${moment().format('YYYY')}-${dateMoment.format('M')}-${dateMoment.format('D')}`
    values.startDate = moment(new Date(date)).add(1, 'days');
    values.endDate = moment(new Date(date)).add(1, 'years');
    delete values.date
    values.clientId = parseInt(clientData.id)
    values.whichYear = parseInt(values.whichYear.format('YYYY'))
    setAMCShowFormModal(true)
    client.mutate({
      mutation: CREATE_AMC,
      variables: { data: values },
    })
      .then((res) => {
        openNotification('success', 'AMC Created Successfully')
        setShowFormModal(false)
        resetForm()
      })
      .catch((err) => console.log(err))
      .finally(() => setAMCShowFormModal(false))
  }

  function renderAction(record) {
    return <div className='action-icons'>
      {record.isDeleted ?
        <Popconfirm
          title={`Are you sure you want to add this user again?`}
          onConfirm={() => handleSwitch(record)}
        >
          <Tooltip title={`Restore user`}><PlusCircleOutlined /></Tooltip>
        </Popconfirm>
        :
        <Fragment>
          <Tooltip title='Edit'>
            <EditTwoTone onClick={() => history.push(`/clients/${record.id}/edit`)} />
          </Tooltip>
          {
            <Switch
              size='small'
              checked={record.isActive}
              loading={switchLoading === record.id}
              onClick={() => handleSwitch(record)}
              disabled={state.currentUser.email === record.email}
            />
          }
        </Fragment>
      }
      <Button
        size='small'
        type='primary'
        style={{ margin: '0px 5px', lineHeight: '1px' }}
        onClick={() => {
          setClientData(record)
          setShowFormModal(true)
        }}
      >
        Add SIP
        </Button>
      <Button
        size='small'
        type='primary'
        style={{ marginBottom: 0, marginRight: 0, lineHeight: '1px' }}
        onClick={() => {
          setClientData(record)
          setAMCShowFormModal(true)
        }}
      >
        Add AMC
        </Button>
    </div>
  }

  function handlePagination(page) {
    setSkip((page - 1) * limit)
    setCurrentPage(page)
  }

  function handlePageSizeChange(current, size) {
    setLimit(size)
  }

  function handleSearch(value, confirm, filedName) {
    value = typeof value === 'object' ? value[0] : value
    confirm()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    tempFilters[filedName] = value
    setFilters(tempFilters)
    setCurrentPage(1)
  }

  function handleReset(clearFilters, filedName) {
    clearFilters()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    tempFilters[filedName] = ''
    setFilters(tempFilters)
    setCurrentPage(1)
  }

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys[0], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters, dataIndex)} size='small' style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
  })

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      render: (text, record) => <NavLink to={`/clients/${record.id}`}>{getFirstName(record)}</NavLink>,
      ...getColumnSearchProps('firstName')
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      render: (text, record) => <NavLink to={`/clients/${record.id}`}>{getLastName(record)}</NavLink>,
      ...getColumnSearchProps('lastName')
    },
    {
      title: 'Username',
      dataIndex: 'userName',
      key: 'userName',
      ...getColumnSearchProps('userName')
    },
    {
      title: 'Client Code',
      dataIndex: 'clientCode',
      key: 'clientCode',
      ...getColumnSearchProps('clientCode')
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: text => <a href={`mailto:${text}`}>{text}</a>,
      ...getColumnSearchProps('email')
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      render: text => <a href={`tel:${text}`}>{text}</a>,
      ...getColumnSearchProps('phone')
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => renderAction(record)
    },
  ]

  return (
    <Fragment>
      <CustomMeta title='Clients' />
      <PageHeader
        className='box'
        title='Clients'
        extra={[
          <Button key='1' type='primary' onClick={() => history.push('/clients/create')}>Add Client</Button>
        ]}
      />
      <Table
        loading={loading}
        columns={columns}
        scroll={{ x: true }}
        dataSource={tableData}
        pagination={{ pageSize: limit, total: totalCount, onChange: handlePagination, current: currentPage, onShowSizeChange: handlePageSizeChange }}
      />
      {
        showFormModal &&
        <CreateSIPModal
          visible={showFormModal}
          loading={showFormLoading}
          onCreate={handleSIPCreate}
          date={clientData.createdAt}
          amount={clientData.sipAmount}
          onCancel={() => {
            setClientData(undefined)
            setShowFormModal(false)
          }}
        />
      }
      {
        showAMCFormModal &&
        <CreateAMCModal
          loading={showFormLoading}
          onCreate={handleAMCCreate}
          visible={showAMCFormModal}
          amount={clientData.amcAmount}
          onCancel={() => {
            setClientData(undefined)
            setAMCShowFormModal(false)
          }}
        />
      }
    </Fragment>
  )
}
