import gql from 'graphql-tag'

export const CREATE_GROUP = gql`
mutation createGroup($data: CreateGroupInput!){
  createGroup(data: $data) {
    id
  }
}
`

export const UPDATE_GROUP = gql`
mutation updateGroup($data: UpdateGroupInput!, $id: ID!){
  updateGroup(data: $data, where: { id: $id }) {
    id
  }
}
`

export const DELETE_GROUP = gql`
mutation deleteGroup($id: ID!){
  deleteGroup(where: { id: $id }) {
    id
  }
}
`

export const SEND_GROUP_NOTIFICATION = gql`
mutation sendGroupNotification($data: SendGroupNotificationInput!){
  sendGroupNotification(data: $data)
}
`
