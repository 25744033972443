import React, { useState, useContext } from 'react'
import { Table, PageHeader } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import { NavLink } from 'react-router-dom'
import get from 'lodash/get'
import moment from 'moment'

import { GET_REVENUE_INCOME } from './graphql/Queries'
import Page404 from 'components/Page404'
import { AppContext } from 'AppContext'
import { getName } from 'utils/User'

export default function () {
  let tableData = []
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const { state: { currentUser: { id: userId } } } = useContext(AppContext)

  const { data, loading, error } = useQuery(GET_REVENUE_INCOME, { variables: { userId: parseInt(userId), skip }, fetchPolicy: 'network-only' })

  if (error) return <Page404 />

  let totalCount = 0
  if (!loading && get(data, 'getRevenueIncome')) {
    tableData = data.getRevenueIncome.map((user, key) => ({ key: key.toString(), ...user }))
  }

  function handlePagination(page) {
    setSkip((page - 1) * limit)
    setCurrentPage(page)
  }

  function handlePageSizeChange(current, size) {
    setLimit(size)
  }

  const columns = [
    {
      title: 'Client',
      dataIndex: 'client',
      key: 'client',
      render: (text, record) => <NavLink to={`/clients/${record.client.id}`}>{getName(record.client)}</NavLink>
    },
    {
      title: 'Ratio',
      dataIndex: 'commissionRatio',
      key: 'commissionRatio',
    },
    {
      title: 'Amount',
      dataIndex: 'commissionAmount',
      key: 'commissionAmount',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: date => moment(date).format('Do MMMM YYYY'),
    }
  ]

  return (
    <>
      <PageHeader className='box' title='Revenue Income' />
      <Table
        loading={loading}
        expandable={{
          expandedRowRender: record => <p style={{ margin: 0 }}>{record.description}</p>,
          rowExpandable: record => record.name !== 'Not Expandable',
        }}
        columns={columns}
        dataSource={tableData}
        scroll={{ x: true }}
        pagination={{ pageSize: limit, total: totalCount, onChange: handlePagination, current: currentPage, onShowSizeChange: handlePageSizeChange }}
      />
    </>
  )
}
