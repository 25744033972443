import gql from 'graphql-tag'

export const GET_NIFTY_CANDLE_CHART = gql`
query niftyCandleChart{
  niftyCandleChart
}
`

export const GET_DASHBOARD_COUNTS = gql`
query dashboardCounters{
  dashboardCounters
}
`

export const GET_DASHBOARD_USER_PERFORMANCE = gql`
query userPerformanceCounter{
  userPerformanceCounter
}
`

export const GET_DASHBOARD_SIP_AMC = gql`
query spDashbordSIPAMC{
  spDashbordSIPAMC
}
`

export const GET_DASHBOARD_SIP_AMC_CLIENT = gql`
query spDashbordSIPAMCClient{
  spDashbordSIPAMCClient
}
`

export const GET_PROFIT_LOSS_CHART = gql`
query profitLossPieChart($groupId: Int){
  profitLossPieChart(where: { groupId: $groupId })
}
`

export const GET_PNL_NLV_EXPOSURE_REPORT = gql`
query pnlNlvExposureReport{
  pnlNlvExposureReport{
    pnlPercentage
    nlvPercentage
    exposurePercentage
  }
}
`
