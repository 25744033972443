import React, { useState } from 'react'
import { UploadOutlined } from '@ant-design/icons'
import { Modal, Form, Input, Radio, Upload, Button } from 'antd'

const CollectionCreateForm = ({ visible, onSend, onCancel }) => {
  const [form] = Form.useForm()
  const [type, setType] = useState('SMS')
  const [fileList, setFileList] = useState(undefined)

  function resetForm() {
    form.resetFields()
  }

  const normFile = e => {
    setFileList([])
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  const customProps = {
    multiple: false,
    onRemove: file => setFileList([]),
    beforeUpload: file => {
      setFileList(file)
      return false
    },
    fileList
  }

  return (
    <Modal
      maskClosable={false}
      visible={visible}
      title={'Send Notification'}
      okText={'Send'}
      cancelText='Cancel'
      onCancel={() => {
        resetForm()
        onCancel()
      }}
      onOk={() => {
        form
          .validateFields()
          .then(values => onSend(values, resetForm))
          .catch(info => console.log('Validate Failed:', info))
      }}
    >
      <Form
        form={form}
        layout='vertical'
        name='form_in_modal'
        initialValues={{ modifier: 'public' }}
      >
        <Form.Item
          name='sendVia'
          label='Notification Type'
          rules={[{ required: true, message: 'Please select notification type!' }]}
        >
          <Radio.Group onChange={e => setType(e.target.value)}>
            <Radio value={'SMS'}>SMS</Radio>
            <Radio value={'EMAIL'}>EMAIL</Radio>
            <Radio value={'BOTH'}>BOTH</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name='content'
          dependencies={['sendVia']}
          label='Notification Content'
          rules={[
            { required: true, message: 'Please write your content!', whitespace: true, min: 2 },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!getFieldValue('sendVia')) return Promise.reject('Please Select Notification Type First!')
                if (!value) return Promise.reject()
                if ((getFieldValue('sendVia') === 'SMS' || getFieldValue('sendVia') === 'BOTH') && value.length > 160) {
                  return Promise.reject('Please input your content between 2 to 160 characters!')
                }
                return Promise.resolve()
              },
            })
          ]}
          hasFeedback
        >
          <Input.TextArea rows={5} />
        </Form.Item>
        {
          type !== 'SMS' &&
          <Form.Item
            name='file'
            label='Attachment'
            valuePropName='fileList'
            getValueFromEvent={normFile}
          >
            <Upload name='attachment' listType='text' {...customProps} accept='.jpeg,.png,.jpg,.pdf,.docx,.doc'>
              <Button><UploadOutlined /> Click to upload</Button>
            </Upload>
          </Form.Item>
        }
      </Form>
    </Modal>
  )
}

const CreateGroupModal = (props) => {
  return (
    <div>
      <CollectionCreateForm {...props} />
    </div>
  )
}

export default CreateGroupModal
