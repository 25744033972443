import React, { Fragment, useState, useContext } from 'react'
import { MenuOutlined, EditOutlined } from '@ant-design/icons'
import { PageHeader, Button, Menu, Dropdown } from 'antd'
import get from 'lodash/get'

import { getName } from 'utils/User'
import About from 'components/About'
import Steps from './components/Steps'
import { AppContext } from 'AppContext'
import Page404 from 'components/Page404'
import CustomMeta from 'components/CustomMeta'
import Loader from 'components/loaders/Loader'
import { useQuery } from '@apollo/react-hooks'
import { GET_USER } from '../Users/graphql/Queries'

export default function (props) {
  let clientDetails = {}
  const [visibleMenu, setVisibleMenu] = useState(false)
  const { history, match: { params: { clientId } } } = props
  const { state: { isAdmin, isStaff, isMasterAssociate } } = useContext(AppContext)
  const { data, loading, error } = useQuery(GET_USER, { variables: { id: clientId }, fetchPolicy: 'network-only' })

  if (loading) return <Loader />
  if (error) return <Page404 />

  if (!loading && get(data, 'getUser')) {
    clientDetails = data.getUser
  }

  const menu = (
    <Menu>
      <Menu.Item key='1' onClick={() => history.push(`/clients/${clientId}/edit`)}>
        <EditOutlined /> Edit
      </Menu.Item>
    </Menu>
  )

  return (
    <Fragment>
      <CustomMeta title={getName(clientDetails)} description='Clients' />
      <PageHeader
        className='box'
        onBack={() => history.goBack()}
        title={getName(clientDetails)}
        extra={[
          window.innerWidth >= 991 ?
            <Fragment key='0'>
              {
                clientDetails.clientCode &&
                <Button key='1' type='primary' onClick={() => history.push(`/clients/${clientId}/position/${clientDetails.clientCode}`)}>View Position</Button>
              }
              <Button key='2' type='primary' onClick={() => history.push(`/clients/${clientId}/edit`)}>Edit</Button>
            </Fragment>
            :
            <Dropdown onVisibleChange={() => setVisibleMenu(false)} key='0' overlay={menu} onClick={() => setVisibleMenu(!visibleMenu)} visible={visibleMenu}>
              <Button><MenuOutlined /></Button>
            </Dropdown>
        ]}
      />
      {
        (isAdmin || isStaff || isMasterAssociate) &&
        <Steps clientId={clientId} completedStep={clientDetails.completedSteps} />
      }
      <About details={clientDetails} />
    </Fragment>
  )
}
