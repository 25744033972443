import React from 'react'
import { Layout } from 'antd'
import { NavLink } from 'react-router-dom'

import Menu from './Menu'
import Favicon from 'assets/images/favicon.png'
import LogoWhite from 'assets/images/logo-white.png'

const { Sider } = Layout

export default function ({ pathname, collapsed }) {
  const logoSrc = collapsed ? Favicon : LogoWhite

  return (
    <Sider className={`gx-app-sidebar gx-layout-sider-dark`} trigger={null} collapsible collapsed={collapsed}>
      <div className='logo'>
        <NavLink to='/' className='nav-text'>
          <img alt='Finideas' src={logoSrc} />
        </NavLink>
      </div>
      <Menu pathname={pathname} />
    </Sider>
  )
}
