import gql from 'graphql-tag'

export const GET_ALL_ACCESS_SETTINGS = gql`
query getAllAccessSettings{
  getAllAccessSettings {
    id
    title
    key
    admin
    staff
    masterAssociate
    associate
    client
    createdAt
    updatedAt
  }
}
`
