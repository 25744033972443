import gql from 'graphql-tag'

export const GET_REVENUE_INCOME = gql`
query getRevenueIncome($userId: Int){
  getRevenueIncome(where: { userId: $userId}) {
    id
    commissionRatio
    commissionAmount
    description
    createdAt
    updatedAt
    client{
      id
      firstName
      lastName
    }
  }
}`
