import gql from 'graphql-tag'

export const GET_CURRENT_USER = gql`
query currentUser{
  currentUser {
    id
    firstName
    lastName
    email
    password
    phone
    address
    cityId
    stateId
    countryId
    motherName
    fatherName
    dateOfBirth
    userName
    isActive
    role
    keyId
    isAuto
    isAssociate
    fundReceived
    isKycDone
    completedSteps
    amcStartDate
    sipStartDate
    closedDate
    initialExposure
    clientCode
    strategyCode
    createdBy {
      id
    }
    updatedBy {
      id
    }
    token
    tokenExpiration
    createdAt
    updatedAt
    kyc {
      id
      panCard
      aadharCard
      passportSize
      signature
      cancelledChequeSnap
      bankStatement
    }
  }
}
`

export const SEND_PASSWORD_LINK = gql`
query sendResetPasswordLink($email: String!){
  sendResetPasswordLink(email: $email)
}
`
