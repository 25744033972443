import React, { useState, useContext } from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import { useQuery } from '@apollo/react-hooks'
import { Select, DatePicker } from 'antd'
import get from 'lodash/get'
import moment from 'moment'

import history from 'CustomHistory'
import Widget from 'components/Widget'
import { AppContext } from 'AppContext'
import { exportGraphAsImage } from 'utils/Graph'
import ChartError from 'components/Page404/ChartError'
import ChartLoader from 'components/loaders/ChartLoader'
import { GET_NLV_TRACKER_REPORT } from '../../Reports/graphql/Queries'

const Option = Select.Option

export default function () {
  const [selectedMonths, setSelectedMonths] = useState(3)
  const [selectedDate, setSelectedDate] = useState(undefined)
  const { state: { currentUser: { clientCode } } } = useContext(AppContext)
  const { data, loading, error } = useQuery(GET_NLV_TRACKER_REPORT, { variables: { where: { clientCode } }, fetchPolicy: 'network-only' })

  if (loading) return <ChartLoader title='Comparison of Fund with Index' />
  if (error) return <ChartError title='Comparison of Fund with Index' />
  if (!get(data, 'spNLVTracker.length')) return <ChartError title='Comparison of Fund with Index' desc='No Data' />

  const { spNLVTracker: [spNLVTracker] } = data

  function handleChange(value) {
    setSelectedMonths(value)
    setSelectedDate(undefined)
  }

  const divId = 'export-graph-NLV-Chart'

  function handleExport(value) {
    exportGraphAsImage('NLV-Chart-Report', value, divId)
  }

  const renderData =
    selectedDate ?
      spNLVTracker
        .map((reportData) => ({ 'name': reportData.ReportDate, 'Index': reportData['NIFTY%'], 'NLV': reportData['TotNLV%'] }))
        .filter((reportData) => moment(reportData.name).isSameOrAfter(selectedDate))
      :
      spNLVTracker
        .map((reportData) => ({ 'name': reportData.ReportDate, 'Index': reportData['NIFTY%'], 'NLV': reportData['TotNLV%'] }))
        .filter((reportData) => moment(reportData.name).isSameOrAfter(moment().subtract(selectedMonths, 'months'), 'months'))

  function disabledDate(current) {
    return current && current > moment().endOf('day')
  }

  return (
    <Widget
      title={<h2 className='h4 gx-text-capitalize gx-mb-0'>Comparison of Fund with Index</h2>}
      extra={<p className="gx-text-primary gx-mb-0 gx-pointer gx-d-none gx-d-sm-block" onClick={() => history.push('/reports/performance')}>View Performance</p>}
      styleName='gx-text-center'
    >
      <div className='ant-row-flex gx-pr-4 gx-pt-4'>
        <div className='gx-ml-auto'>
          <DatePicker
            disabledDate={disabledDate}
            value={selectedDate}
            showToday={false}
            onChange={e => {
              if (e) {
                setSelectedDate(e)
                setSelectedMonths('')
              } else {
                setSelectedDate(undefined)
                setSelectedMonths(3)
              }
            }}
          />
          &nbsp;
          <Select className='gx-mb-2 gx-select-sm' defaultValue='3' onChange={handleChange} value={selectedMonths ? `Last ${selectedMonths} months` : selectedMonths}>
            <Option value='' disabled>Select Months</Option>
            <Option value='3'>Last 3 months</Option>
            <Option value='6'>Last 6 months</Option>
            <Option value='12'>Last 12 months</Option>
          </Select>
          &nbsp;
          <Select className='gx-mb-2 gx-select-sm' value='1' onChange={handleExport}>
            <Option value='1' disabled>Export As</Option>
            <Option value='png'>PNG</Option>
            <Option value='jpeg'>JPEG</Option>
          </Select>
        </div>
      </div>
      <ResponsiveContainer width='100%' height={250} id={divId}>
        <LineChart
          data={renderData}
          margin={{ top: 5, right: 30, left: 0, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='name' hide={true} />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type='monotone' dataKey='Index' stroke='#8884d8' activeDot={{ r: 8 }} />
          <Line type='monotone' dataKey='NLV' stroke='#82ca9d' />
        </LineChart>
      </ResponsiveContainer>
    </Widget>
  )
}
