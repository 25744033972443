import React from 'react'
import get from 'lodash/get'
import { useQuery } from '@apollo/react-hooks'
import { Table, Checkbox, message } from 'antd'

import client from 'apollo'
import Page404 from 'components/Page404'
import { GET_ALL_ACCESS_SETTINGS } from './graphql/Queries'
import { UPDATE_ACCESS_SETTING } from './graphql/Mutations'
import './index.css'

export default function () {

  const { data, loading, error } = useQuery(GET_ALL_ACCESS_SETTINGS, { fetchPolicy: 'network-only' })

  if (error) return <Page404 />

  function handleChange(e, id, type) {
    const data = {}
    const where = { id }
    data[type] = e.target.checked
    client.mutate({
      mutation: UPDATE_ACCESS_SETTING,
      variables: { where, data },
      refetchQueries: [{ query: GET_ALL_ACCESS_SETTINGS, fetchPolicy: 'network-only' }]
    })
      .then((res) => message.success('Setting Updated!'))
      .catch((err) => console.log(err))
  }

  const columns = [
    {
      title: 'Report Name',
      dataIndex: 'reportName'
    },
    {
      title: 'Admin',
      dataIndex: 'admin',
      render: (text, record) => <Checkbox defaultChecked={text} onChange={(e) => handleChange(e, record.id, 'admin')} />
    },
    {
      title: 'Staff',
      dataIndex: 'staff',
      render: (text, record) => <Checkbox defaultChecked={text} onChange={(e) => handleChange(e, record.id, 'staff')} />
    },
    {
      title: 'Master Associate',
      dataIndex: 'masterAssociate',
      render: (text, record) => <Checkbox defaultChecked={text} onChange={(e) => handleChange(e, record.id, 'masterAssociate')} />
    },
    {
      title: 'Associate',
      dataIndex: 'associate',
      render: (text, record) => <Checkbox defaultChecked={text} onChange={(e) => handleChange(e, record.id, 'associate')} />
    },
    {
      title: 'Client',
      dataIndex: 'client',
      render: (text, record) => <Checkbox defaultChecked={text} onChange={(e) => handleChange(e, record.id, 'client')} />
    }
  ]

  let tableData = []

  if (!loading && get(data, 'getAllAccessSettings')) {
    tableData = data.getAllAccessSettings.map((getAllAccessSetting) => {
      return {
        key: getAllAccessSetting.id,
        id: getAllAccessSetting.id,
        reportName: getAllAccessSetting.title,
        admin: getAllAccessSetting.admin,
        staff: getAllAccessSetting.staff,
        masterAssociate: getAllAccessSetting.masterAssociate,
        associate: getAllAccessSetting.associate,
        client: getAllAccessSetting.client,
      }
    })
  }


  return (
    <Table
      loading={loading}
      columns={columns}
      dataSource={tableData}
      // title={() => 'Reports'}
      className='settings-table'
      pagination={{ pageSize: 1000, hideOnSinglePage: true }}
    />
  )
}
