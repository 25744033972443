import React, { Fragment, useState, useContext } from 'react'
import { useQuery } from '@apollo/react-hooks'
import isEmpty from 'lodash/isEmpty'
import { PageHeader } from 'antd'
import pick from 'lodash/pick'
import omit from 'lodash/omit'
import get from 'lodash/get'
import moment from 'moment'

import client from 'apollo'
import { getName } from 'utils/User'
import { AppContext } from 'AppContext'
import Page404 from 'components/Page404'
import CustomMeta from 'components/CustomMeta'
import Loader from 'components/loaders/Loader'
import ClientForm from './components/ClientForm'
import openNotification from 'utils/Notification'
import { GET_USER } from '../Users/graphql/Queries'
import { GET_CURRENT_USER } from 'auth/graphql/Queries'
import { UPDATE_USER, UPDATE_KYC } from '../Users/graphql/Mutations'

function getFilesObject(tempFiles) {
  let files = pick(tempFiles, ['panCard', 'signature', 'aadharCard', 'passportSize', 'bankStatement', 'cancelledChequeSnap'])
  if (get(files, 'panCard[0].originFileObj')) {
    files.panCard = get(files, 'panCard[0].originFileObj')
  } else {
    delete files.panCard
  }
  if (get(files, 'signature[0].originFileObj')) {
    files.signature = get(files, 'signature[0].originFileObj')
  } else {
    delete files.signature
  }
  if (get(files, 'aadharCard[0].originFileObj')) {
    files.aadharCard = get(files, 'aadharCard[0].originFileObj')
  } else {
    delete files.aadharCard
  }
  if (get(files, 'passportSize[0].originFileObj')) {
    files.passportSize = get(files, 'passportSize[0].originFileObj')
  } else {
    delete files.passportSize
  }
  if (get(files, 'bankStatement[0].originFileObj')) {
    files.bankStatement = get(files, 'bankStatement[0].originFileObj')
  } else {
    delete files.bankStatement
  }
  if (get(files, 'cancelledChequeSnap[0].originFileObj')) {
    files.cancelledChequeSnap = get(files, 'cancelledChequeSnap[0].originFileObj')
  } else {
    delete files.cancelledChequeSnap
  }
  return files
}

export default function (props) {
  let initialValues = {}
  const { state, dispatch } = useContext(AppContext)
  const [formLoading, setFormLoading] = useState(false)
  let { history, match: { path, params: { clientId } } } = props
  const isProfilePage = path.includes('profile')
  clientId = clientId || state.currentUser.id
  const { data, loading, error } = useQuery(GET_USER, { variables: { id: clientId }, fetchPolicy: 'network-only' })

  if (loading) return <Loader />
  if (error) return <Page404 />

  if (!loading && get(data, 'getUser')) {
    initialValues = JSON.parse(JSON.stringify(data.getUser))
    initialValues.dateOfBirth = initialValues.dateOfBirth ? moment(initialValues.dateOfBirth) : undefined
    initialValues.amcStartDate = initialValues.amcStartDate ? moment(initialValues.amcStartDate) : undefined
    initialValues.sipStartDate = initialValues.sipStartDate ? moment(initialValues.sipStartDate) : undefined
    initialValues.closedDate = initialValues.closedDate ? moment(initialValues.closedDate) : undefined
    initialValues.createdAt = initialValues.createdAt ? moment(initialValues.createdAt) : undefined
    initialValues.createdById = initialValues.createdBy ? initialValues.createdBy.id : undefined
  }

  async function onFinish(values) {
    setFormLoading(true)
    const data = omit(values, ['panCard', 'signature', 'aadharCard', 'passportSize', 'bankStatement', 'cancelledChequeSnap'])
    const queryVariables = { id: clientId, data }
    client.mutate({ mutation: UPDATE_USER, variables: queryVariables })
      .then((res) => {
        const files = getFilesObject(values)
        if (isEmpty(files)) {
          openNotification('success', 'Client Updated Successfully')
          if (isProfilePage) {
            client.query({ query: GET_CURRENT_USER, fetchPolicy: 'network-only' })
              .then((res) => dispatch({ type: 'CURRENT_USER', data: res.data.currentUser }))
              .catch((err) => console.log(err))
          }
          history.goBack()
        } else {
          client.mutate({ mutation: UPDATE_KYC, variables: { id: parseInt(clientId), ...files } })
            .then((res) => {
              openNotification('success', 'Client Updated Successfully')
              history.goBack()
            })
            .catch((err) => console.log(err))
            .finally(() => setFormLoading(false))
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setFormLoading(false))
  }

  return (
    <Fragment>
      <CustomMeta title={getName(initialValues)} description='Edit Client' />
      <PageHeader
        className='box'
        onBack={() => history.goBack()}
        title={isProfilePage ? 'Edit Details' : `Edit Client`}
      />
      <ClientForm onFinish={onFinish} initialValues={initialValues} loading={formLoading} isUpdate />
    </Fragment>
  )
}
