import React, { useState, useContext } from 'react'
import { Tooltip, Table, PageHeader, Select } from 'antd'
import { EditTwoTone } from '@ant-design/icons'
import { useQuery } from '@apollo/react-hooks'
import { NavLink } from 'react-router-dom'
import get from 'lodash/get'
import moment from 'moment'

import { UPDATE_SIP } from 'modules/Users/graphql/Mutations'
import { GET_SIP } from 'modules/Users/graphql/Queries'
import openNotification from 'utils/Notification'
import CreateSIPModal from 'modules/SIP/Modal'
import Page404 from 'components/Page404'
import { AppContext } from 'AppContext'
import { getName } from 'utils/User'
import client from 'apollo'

const { Option } = Select
const months = ['All', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

export default function () {
  let tableData = []
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [isUpdate, setIsUpdate] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const { state: { isClient, currentUser: { id } } } = useContext(AppContext)
  const [filters, setFilters] = useState(isClient ? { clientId: parseInt(id) } : {})
  const [updateData, setUpdateData] = useState(undefined)
  const [showFormModal, setShowFormModal] = useState(false)
  const [showFormLoading, setShowFormLoading] = useState(false)

  const { data, loading, error } = useQuery(GET_SIP, { variables: { ...filters, skip, limit }, fetchPolicy: 'network-only' })

  if (error) return <Page404 />

  let totalCount = 0
  if (!loading && get(data, 'getAllSips.sip')) {
    totalCount = data.getAllSips.count
    tableData = data.getAllSips.sip.map((user, key) => ({ key: key.toString(), ...user }))
  }

  function handlePagination(page) {
    setSkip((page - 1) * limit)
    setCurrentPage(page)
  }

  function handlePageSizeChange(current, size) {
    setLimit(size)
  }

  function handleUpdate(values, resetForm) {
    setShowFormLoading(true)
    client.mutate({
      mutation: UPDATE_SIP,
      variables: { data: values, id: updateData.id },
      refetchQueries: [{ query: GET_SIP, variables: { ...filters, skip, limit }, fetchPolicy: 'network-only' }]
    })
      .then((res) => {
        openNotification('success', 'SIP Updated Successfully')
        setShowFormModal(false)
        setIsUpdate(false)
        setUpdateData(undefined)
        resetForm()
      })
      .catch((err) => console.log(err))
      .finally(() => setShowFormLoading(false))
  }

  function renderAction(record) {
    return <div className='action-icons'>
      <Tooltip title='Edit'>
        <EditTwoTone
          onClick={() => {
            setIsUpdate(true)
            setUpdateData(record)
            setShowFormModal(true)
          }}
        />
      </Tooltip>
    </div>
  }

  function handleSearch(value, confirm, filedName) {
    value = typeof value === 'object' ? value[0] : value
    confirm()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    tempFilters[filedName] = value
    setFilters(tempFilters)
    setCurrentPage(1)
  }

  function handleReset(clearFilters, filedName) {
    clearFilters()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    delete tempFilters[filedName]
    setFilters(tempFilters)
    setCurrentPage(1)
  }

  // const getColumnSearchProps = dataIndex => ({
  //   filterDropdown: ({ setSelectedKeys, confirm, clearFilters }) => (
  //     <div style={{ padding: 8 }}>
  //       <DatePicker
  //         picker='year'
  //         onChange={e => {
  //           if (e) {
  //             handleSearch(parseInt(e.format('YYYY')), confirm, dataIndex)
  //           } else {
  //             handleReset(clearFilters, dataIndex)
  //           }
  //         }}
  //       />
  //     </div>
  //   ),
  // })

  const getColumnDropDownProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Select
          placeholder='Select Month'
          style={{ minWidth: 120 }}
          onChange={e => {
            if (e === 'All') {
              handleReset(clearFilters, dataIndex)
            } else {
              handleSearch(e, confirm, dataIndex)
            }
          }}
        >
          {months.map((month) => <Option key={month} value={month}>{month}</Option>)}
        </Select>
      </div>
    ),
  })

  const columns = [
    {
      title: 'Client',
      dataIndex: 'client',
      key: 'client',
      render: (text, record) => <NavLink to={`/clients/${record.client.id}`}>{getName(record.client)}</NavLink>
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Month',
      dataIndex: 'whichMonth',
      key: 'whichMonth',
      ...getColumnDropDownProps('whichMonth')
    },
    {
      title: 'Payment Type',
      dataIndex: 'paymentType',
      key: 'paymentType',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: date => moment(date).format('Do MMMM YYYY'),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => renderAction(record)
    },
  ]

  return (
    <>
      {
        showFormModal &&
        <CreateSIPModal
          data={updateData}
          isUpdate={isUpdate}
          visible={showFormModal}
          onUpdate={handleUpdate}
          loading={showFormLoading}
          onCancel={() => {
            setShowFormModal(false)
            setUpdateData(undefined)
            setIsUpdate(false)
          }}
        />
      }
      <PageHeader className='box' title='SIPs' />
      <Table
        loading={loading}
        expandable={{
          expandedRowRender: record => <p style={{ margin: 0 }}>{record.description}</p>,
          rowExpandable: record => record.name !== 'Not Expandable',
        }}
        scroll={{ x: true }}
        dataSource={tableData}
        columns={isClient ? columns.slice(0, columns.length - 1) : columns}
        pagination={{ pageSize: limit, total: totalCount, onChange: handlePagination, current: currentPage, onShowSizeChange: handlePageSizeChange }}
      />
    </>
  )
}
