import React, { useContext } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Button } from 'antd'
import { get } from 'lodash'

import NoData from 'components/NoData'
import { AppContext } from 'AppContext'
import Page404 from 'components/Page404'
import { exportGraphAsPdf } from 'utils/Graph'
import Loader from 'components/loaders/Loader'
import NlvTrackerTable from './NlvTrackerTable'
import { GET_NLV_TRACKER_REPORT } from '../graphql/Queries'
import '../index.css'

export default function () {
  const { state: { currentUser: { clientCode } } } = useContext(AppContext)
  const { data, loading, error } = useQuery(GET_NLV_TRACKER_REPORT, { variables: { where: { clientCode } }, fetchPolicy: 'network-only' })

  if (loading) return <Loader />
  if (error) return <Page404 />
  if (!get(data, 'spNLVTracker')) return <NoData />

  const { spNLVTracker: [spNLVTracker] } = data

  function generatePdf() {
    exportGraphAsPdf('NLV-Tracker-report.pdf', 'NLV-TRACKER-REPORT-PDF')
  }

  return (
    <>
      <Button className='download-button' key='2' type='primary' size='small' onClick={() => generatePdf()}>Download</Button>
      <div className='report-table-wrapper' id='NLV-TRACKER-REPORT-PDF'>
        <NlvTrackerTable reportData={spNLVTracker.filter((report, key) => key !== 0)} />
      </div>
    </>
  )
}
