import React, { useContext } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Button } from 'antd'
import get from 'lodash/get'

import NoData from 'components/NoData'
import { AppContext } from 'AppContext'
import Page404 from 'components/Page404'
import { exportGraphAsPdf } from 'utils/Graph'
import Loader from 'components/loaders/Loader'
import InterestTable from 'modules/Reports/Interest/InterestTable'
import { GET_INTEREST_REPORT } from 'modules/Reports/graphql/Queries'
import 'modules/Reports/index.css'

export default function (props) {
  const { state: { currentUser: { clientCode } } } = useContext(AppContext)
  const { data, loading, error } = useQuery(GET_INTEREST_REPORT
    , { variables: { where: { clientCode, date: null, strategy: 'ILTS' } } })

  if (loading) return <Loader />
  if (error) return <Page404 />
  if (!get(data, 'spInterestReport')) return <NoData />

  const { spInterestReport: [spInterestReport] } = data

  function generatePdf() {
    exportGraphAsPdf('interest-report.pdf', 'INTEREST-REPORT-PDF')
  }

  return (
    <>
      <Button className='download-button' key='2' type='primary' size='small' onClick={() => generatePdf()}>Download</Button>
      <div className='report-table-wrapper' id='INTEREST-REPORT-PDF'>
        <InterestTable reportData={spInterestReport} />
      </div>
    </>
  )
}
