import React, { useContext } from 'react'
import { useQuery } from '@apollo/react-hooks'
import first from 'lodash/first'
import { Button } from 'antd'
import get from 'lodash/get'

import ILTS from './Tables/ILTS'
import Stock from './Tables/Stock'
import Ledger from './Tables/Ledger'
import NoData from 'components/NoData'
import { AppContext } from 'AppContext'
import Page404 from 'components/Page404'
import Loader from 'components/loaders/Loader'
import { exportGraphAsPdf } from 'utils/Graph'
import OpenPosition from './Tables/OpenPosition'
import { GET_ILTS_REPORT } from '../graphql/Queries'
import '../index.css'

export default function () {
  const { state: { currentUser: { id, clientCode } } } = useContext(AppContext)
  const { data, loading, error } = useQuery(GET_ILTS_REPORT, { variables: { where: { clientCode, date: null } } })

  if (loading) return <Loader />
  if (error) return <Page404 />
  if (!get(data, 'spIltsreport')) return <NoData />

  function generatePdf() {
    exportGraphAsPdf('ILTS.pdf', 'ILTS-PDF')
  }

  const { spIltsreport: [spIltsreport] } = data

  return (
    <>
      <Button className='download-button' key='2' type='primary' size='small' onClick={() => generatePdf()}>Download</Button>
      <div className='report-table-wrapper' id='ILTS-PDF'>
        <ILTS reportData={first(spIltsreport)} />
        <br />
        <br />
        <Stock reportData={spIltsreport.filter((report, key) => (report.Script && report.Script.endsWith(' EQ')))} />
        <br />
        <br />
        <OpenPosition reportData={spIltsreport.filter((report, key) => (report.Script && !report.Script.endsWith(' EQ')))} />
        <br />
        <br />
        <Ledger reportData={spIltsreport.filter((report, key) => ((key !== 0 && key !== 1 && key !== 2) && !report.Script))} />
      </div>
    </>
  )
}
