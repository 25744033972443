import React, { useContext } from 'react'
import { AppContext } from 'AppContext'
import Page404 from 'components/Page404'
import Dashboard from 'modules/Dashboard'
import { Switch, Route } from 'react-router-dom'

import Profile from 'modules/Profile'

import Users from 'modules/Users'
import User from 'modules/Users/User'
import EditUser from 'modules/Users/EditUser'
import CreateUser from 'modules/Users/CreateUser'

import Clients from 'modules/Clients'
import Client from 'modules/Clients/Client'
import EditClient from 'modules/Clients/EditClient'
import NiftyEntry from 'modules/Clients/NiftyEntry'
import CreateClient from 'modules/Clients/CreateClient'
import MonthlyReturn from 'modules/Clients/MonthlyReturn'
import ClientPosition from 'modules/Clients/ClientPosition'
import MonthlyExposure from 'modules/Clients/MonthlyExposure'
import SavingOfInterest from 'modules/Clients/SavingOfInterest'

import Groups from 'modules/Groups'
import Group from 'modules/Groups/Group'

import Tickets from 'modules/Tickets'
import Ticket from 'modules/Tickets/Ticket'

import Ilts from 'modules/Reports/ILTS'
import UnHedge from 'modules/Reports/UnHedge'
import Interest from 'modules/Reports/Interest'
import NLVTracker from 'modules/Reports/NLVTracker'
import Performance from 'modules/Reports/Performance'
import NlvMisMatch from 'modules/Reports/NLVMisMatch'
import AverageIndex from 'modules/Reports/AverageIndex'
import LedgerReport from 'modules/Reports/LedgerReport'
import TradeReportEQ from 'modules/Reports/TradeReportEQ'
import TradeReportFO from 'modules/Reports/TradeReportFO'
import UserPerformance from 'modules/Reports/UserPerformance'
import CurrentPosition from 'modules/Reports/CurrentPosition'

import AMC from 'modules/AMC'
import SIP from 'modules/SIP'

import Revenue from 'modules/Revenue'

import Messages from 'modules/Messages'
import Settings from 'modules/Settings'

export default function () {
  const { state: { isClient, isAssociate, isAdmin, isStaff, currentReport } } = useContext(AppContext)

  return (
    <Switch>
      <Route exact path='/' component={Dashboard} />
      <Route exact path='/profile' component={Profile} />
      <Route exact path='/profile/edit' component={isClient ? EditClient : EditUser} />
      {!(isClient || isAssociate) && <Route exact path='/users' component={Users} />}
      {!(isClient || isAssociate) && <Route exact path='/users/create' component={CreateUser} />}
      {!(isClient || isAssociate) && <Route exact path='/users/:userId' component={User} />}
      {!(isClient || isAssociate) && <Route exact path='/users/:userId/edit' component={EditUser} />}
      {!isClient && <Route exact path='/clients' component={Clients} />}
      {!isClient && <Route exact path='/clients/create' component={CreateClient} />}
      {!isClient && <Route exact path='/clients/:clientId' component={Client} />}
      {!isClient && <Route exact path='/clients/:clientId/edit' component={EditClient} />}
      {!isClient && <Route exact path='/clients/:clientId/position/:clientCode' component={ClientPosition} />}
      {!isClient && <Route exact path='/clients/:clientId/saving-of-interest/:clientCode' component={SavingOfInterest} />}
      {!isClient && <Route exact path='/clients/:clientId/nifty-entry/:clientCode' component={NiftyEntry} />}
      {!isClient && <Route exact path='/clients/:clientId/monthly-return/:clientCode' component={MonthlyReturn} />}
      {!isClient && <Route exact path='/clients/:clientId/monthly-exposure/:clientCode/:pnl' component={MonthlyExposure} />}
      {!isClient && <Route exact path='/groups' component={Groups} />}
      {!isClient && <Route exact path='/groups/:groupId' component={Group} />}
      <Route exact path='/amc' component={AMC} />
      <Route exact path='/sip' component={SIP} />
      {!isClient && !isAdmin && <Route exact path='/revenue-income' component={Revenue} />}
      {isClient && currentReport.includes('ILTS') && <Route exact path='/reports/performance' component={Ilts} />}
      {isClient && currentReport.includes('Interest') && <Route exact path='/reports/interest' component={Interest} />}
      {isClient && currentReport.includes('Performance') && <Route exact path='/reports/cost-to-profit' component={Performance} />}
      {isClient && currentReport.includes('Average Index') && <Route exact path='/reports/average-index' component={AverageIndex} />}
      {isClient && currentReport.includes('NLV Tracker') && <Route exact path='/reports/comparison-of-fund' component={NLVTracker} />}
      {isClient && currentReport.includes('NLV Mismatch') && <Route exact path='/reports/nlv-mismatch' component={NlvMisMatch} />}
      {isClient && currentReport.includes('Unhedge') && <Route exact path='/reports/unhedge' component={UnHedge} />}
      {isClient && currentReport.includes('Current Position') && <Route exact path='/reports/current-position' component={CurrentPosition} />}
      {!isClient && <Route exact path='/reports/trade-eq' component={TradeReportEQ} />}
      {!isClient && <Route exact path='/reports/trade-fo' component={TradeReportFO} />}
      {!isClient && <Route exact path='/reports/ledger' component={LedgerReport} />}
      <Route exact path='/tickets' component={Tickets} />
      <Route exact path='/tickets/:ticketId' component={Ticket} />
      {(isAdmin || isStaff) && <Route exact path='/messages' component={Messages} />}
      {(isAdmin || isStaff) && <Route exact path='/settings' component={Settings} />}
      <Route component={Page404} />
    </Switch>
  )
}
