import React, { useState } from 'react'
import moment from 'moment'
import { get } from 'lodash'
import { Modal, Form, Input, InputNumber, Select, DatePicker, Checkbox } from 'antd'

const { Option } = Select

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

const CollectionCreateForm = ({ visible, onCreate, onCancel, loading, isUpdate, onUpdate, amount, data, date }) => {
  const [isMultiple, setIsMultiple] = useState(false)
  const [selectedMonth, setSelectedMonth] = useState(false)
  date = date ? moment() : moment(date)
  if (get(data, 'client.id')) {
    data.clientId = get(data, 'client.id')
  }

  const [form] = Form.useForm()

  function resetForm() {
    form.resetFields()
  }

  const paymentTypes = [
    { text: 'CASH', value: 'CASH' },
    { text: 'CHEQUE', value: 'CHEQUE' },
    { text: 'ONLINE', value: 'ONLINE' },
  ]

  const statusTypes = [
    { text: 'PENDING', value: 'PENDING' },
    { text: 'COMPLETED', value: 'COMPLETED' },
  ]

  const year = moment()
  const status = 'COMPLETED'
  const whichMonth = year.format('MMMM')

  return (
    <Modal
      maskClosable={false}
      visible={visible}
      title={isUpdate ? 'Update SIP' : 'Create SIP'}
      okText={isUpdate ? 'Update' : 'Create'}
      okButtonProps={{
        loading
      }}
      cancelText='Cancel'
      onCancel={() => {
        resetForm()
        onCancel()
      }}
      onOk={() => {

        form
          .validateFields()
          .then(values => {
            if (isUpdate) {
              onUpdate(values, resetForm)
            } else {
              onCreate(values, resetForm)
            }
          })
          .catch(info => console.log('Validate Failed:', info))
      }}
    >
      <Form
        form={form}
        layout='vertical'
        name='form_in_modal'
        initialValues={{
          date,
          year,
          status,
          amount,
          whichMonth,
          modifier: 'public',
          ...data
        }}
      >
        <Form.Item
          name='amount'
          label='Amount'
          rules={[{ required: true, message: 'Please input your Amount!' }]}
        >
          <InputNumber
            min={0}
            style={{ width: '100%' }}
            placeholder='Enter Amount'
          />
        </Form.Item>
        <Form.Item name='multiple'>
          <Checkbox
            onChange={() => {
              setIsMultiple(!isMultiple)
              form.setFieldsValue({
                multiple: !isMultiple,
              })
            }}
          >
            Multiple
            </Checkbox>
        </Form.Item>
        {
          isMultiple &&
          <Form.Item
            name='whichMonthFrom'
            label='From Month'
            rules={[{ required: true, message: 'Please Select from Which Month!' }]}

          >
            <Select
              placeholder='Select From Month'
              onChange={(e) => {
                setSelectedMonth(months.findIndex((m) => m === e) + 1)
                form.setFieldsValue({
                  whichMonthTo: undefined,
                })
              }}
            >
              {months.map((month) => <Option key={month} value={month}>{month}</Option>)}
            </Select>
          </Form.Item>
        }

        {isMultiple &&
          <Form.Item
            name='whichMonthTo'
            label='To Month'
            rules={[
              { required: true, message: 'Please Select to Which Month!' },
            ]}
            notFoundContent='Please Select from month First!'
          >
            <Select placeholder='Select To Month'>
              {!!selectedMonth && months.slice(selectedMonth, months.length + 1).map((month) => <Option key={month} value={month}>{month}</Option>)}
            </Select>
          </Form.Item>
        }

        {!isMultiple &&
          <Form.Item
            name='whichMonth'
            label='Month Of'
            rules={[{ required: true, message: 'Please input your Which Month!' }]}
          >
            <Select placeholder='Select Month'>
              {months.map((month) => <Option key={month} value={month}>{month}</Option>)}
            </Select>
          </Form.Item>
        }
        <Form.Item
          name='paymentType'
          label='Select Payment Type'
          rules={[{ required: true, message: 'Please Select Payment Type!', whitespace: true }]}
        >
          <Select placeholder='Select Payment Type'>
            {paymentTypes.map((paymentType) => <Option key={paymentType.value} value={paymentType.value}>{paymentType.text}</Option>)}
          </Select>
        </Form.Item>
        <Form.Item
          name='status'
          label='Select Status Type'
          rules={[{ required: true, message: 'Please Select Status Type!', whitespace: true }]}
        >
          <Select placeholder='Select Status Type'>
            {statusTypes.map((statusType) => <Option key={statusType.value} value={statusType.value}>{statusType.text}</Option>)}
          </Select>
        </Form.Item>
        <Form.Item
          hasFeedback
          name='description'
          label='Description'
          rules={[{ required: true, message: 'Please write your description!', whitespace: true }]}
        >
          <Input.TextArea rows={5} />
        </Form.Item>
        <Form.Item name='date' style={{ display: 'none' }}>
          <DatePicker />
        </Form.Item>
      </Form>
    </Modal >
  )
}

const CreateTicketModal = (props) => {
  return (
    <div>
      <CollectionCreateForm
        {...props}
      />
    </div>
  )
}

export default CreateTicketModal
