import React from 'react'
import { Col } from 'antd'
import get from 'lodash/get'
import { useQuery } from '@apollo/react-hooks'

import Portfolio from '../Portfolio'
import { GET_DASHBOARD_SIP_AMC } from 'modules/Dashboard/graphql/Queries'

export default function () {
  const { data, loading } = useQuery(GET_DASHBOARD_SIP_AMC, { fetchPolicy: 'network-only' })
  if (loading || !get(data, 'spDashbordSIPAMC') || !get(data, 'spDashbordSIPAMC')[0] || !get(data, 'spDashbordSIPAMC')[1]) return <></>

  return (
    <>
      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
        <Portfolio type='AMC' {...get(data, 'spDashbordSIPAMC')[1]} />
      </Col>
      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
        <Portfolio {...get(data, 'spDashbordSIPAMC')[0]} />
      </Col>
    </>
  )
}
