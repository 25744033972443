import React from 'react'
import { Col } from 'antd'
import get from 'lodash/get'
import { useQuery } from '@apollo/react-hooks'

import IconWithTextCard from '../IconWithTextCard'
import { generateUserPerformanceData } from 'utils/misc'
import { GET_DASHBOARD_USER_PERFORMANCE } from 'modules/Dashboard/graphql/Queries'

export default function () {
  let userPerformanceCounter
  const { data, loading } = useQuery(GET_DASHBOARD_USER_PERFORMANCE, { fetchPolicy: 'network-only' })

  if (loading || !get(data, 'userPerformanceCounter')) return <></>

  if (get(data, 'userPerformanceCounter')) {
    userPerformanceCounter = generateUserPerformanceData(get(data, 'userPerformanceCounter'))
  }

  return (
    userPerformanceCounter && userPerformanceCounter.map((data, key) =>
      <Col key={key} xl={8} lg={8} md={8} sm={8} xs={24}>
        <IconWithTextCard {...data} />
      </Col>
    )
  )
}
