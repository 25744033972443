import React from 'react'
import { get } from 'lodash'
import { Button } from 'antd'
import { useQuery } from '@apollo/react-hooks'

import NoData from 'components/NoData'
import Page404 from 'components/Page404'
import { exportGraphAsPdf } from 'utils/Graph'
import Loader from 'components/loaders/Loader'
import NlvMisMatchTable from './NlvMisMatchTable'
import { GET_NLV_MISMATCH_REPORT } from '../graphql/Queries'
import '../index.css'

export default function () {
  const { data, loading, error } = useQuery(GET_NLV_MISMATCH_REPORT, { variables: { where: { afterBod: 0, strategy: 'ILTS' } } })

  if (loading) return <Loader />
  if (error) return <Page404 />
  if (!get(data, 'spNlvMisMatchReport')) return <NoData />

  const { spNlvMisMatchReport: [spNlvMisMatchReport] } = data

  function generatePdf() {
    exportGraphAsPdf('NLV-report.pdf', 'NLV-REPORT-PDF')
  }

  return (
    <>
      <Button className='download-button' key='2' type='primary' size='small' onClick={() => generatePdf()}>Download</Button>
      <div className='report-table-wrapper' id='NLV-REPORT-PDF'>
        <NlvMisMatchTable reportData={spNlvMisMatchReport.filter((report, key) => key !== 0)} />
      </div>
    </>
  )
}
