import React from 'react'
import get from 'lodash/get'
import { Button } from 'antd'
import first from 'lodash/first'
import { useQuery } from '@apollo/react-hooks'

import NoData from 'components/NoData'
import Page404 from 'components/Page404'
import { exportGraphAsPdf } from 'utils/Graph'
import Loader from 'components/loaders/Loader'
import ILTS from 'modules/Reports/ILTS/Tables/ILTS'
import Stock from 'modules/Reports/ILTS/Tables/Stock'
import Ledger from 'modules/Reports/ILTS/Tables/Ledger'
import { GET_ILTS_REPORT } from 'modules/Reports/graphql/Queries'
import OpenPosition from 'modules/Reports/ILTS/Tables/OpenPosition'
import 'modules/Reports/index.css'

export default function (props) {
  let { match: { params: { clientCode, clientId } } } = props
  const { data, loading, error } = useQuery(GET_ILTS_REPORT, { variables: { where: { clientCode, date: null }, fetchPolicy: 'network-only' } })

  if (loading) return <Loader />
  if (error) return <Page404 />
  if (!get(data, 'spIltsreport')) return <NoData />

  const { spIltsreport: [spIltsreport] } = data

  function generatePdf() {
    exportGraphAsPdf('ILTS.pdf', 'ILTS-REPORT-PDF')
  }

  return (
    <>
      <Button className='download-button' key='2' type='primary' size='small' onClick={() => generatePdf()}>Download</Button>
      <div className='report-table-wrapper' id='ILTS-REPORT-PDF'>
        <ILTS reportData={first(spIltsreport)} clientId={clientId} />
        <br />
        <br />
        <Stock reportData={spIltsreport.filter((report, key) => (report.Script && report.Script.endsWith(' EQ')))} />
        <br />
        <br />
        <OpenPosition reportData={spIltsreport.filter((report, key) => (report.Script && !report.Script.endsWith(' EQ')))} />
        <br />
        <br />
        <Ledger reportData={spIltsreport.filter((report, key) => ((key !== 0 && key !== 1 && key !== 2) && !report.Script))} />
      </div>
    </>
  )
}
