import React from 'react'
import { Table } from 'antd'

const columns = [
  {
    title: 'Ledger Report',
    className: 'report-title main-title',
    children: [
      {
        title: 'Entry Date',
        dataIndex: 'Entrydate',
        key: 'Entrydate',
        className: 'report-text',
        width: '7%'
      },
      {
        title: 'Type',
        dataIndex: 'Type',
        key: 'Type',
        className: 'report-text',
      },
      {
        title: 'Remark',
        dataIndex: 'remark',
        key: 'remark',
        className: 'report-text',
        width: '15%'
      },
      {
        title: 'DR Amount',
        dataIndex: 'DR_Amount',
        key: 'DR_Amount',
        className: 'report-numeric'
      },
      {
        title: 'CR Amount',
        dataIndex: 'CR_Amount',
        key: 'CR_Amount',
        className: 'report-numeric'
      },
      {
        title: 'Strategy Code',
        dataIndex: 'StrategyCode',
        key: 'StrategyCode',
        className: 'report-text'
      },
      {
        title: 'Client Code',
        dataIndex: 'ClientCode',
        key: 'ClientCode',
        className: 'report-text'
      },
      {
        title: 'Member Code',
        dataIndex: 'membercode',
        key: 'membercode',
        className: 'report-text'
      }
    ]
  }
]

export default function ({ reportData }) {
  const data = reportData.map((report, key) => { return { key, ...report } })

  return (
    <Table
      dataSource={data}
      columns={columns}
      className='nlv-tracker-report'
      pagination={{ pageSize: 5000, hideOnSinglePage: true }}
      scroll={{ x: true, y: 'calc(100vh - 72px - 24px - 24px - 36px - 35px)' }}
    />
  )
}
