import React, { useContext } from 'react'
import { Col, Row } from 'antd'

import { AppContext } from 'AppContext'
import Counts from './Components/Counts'
import CustomMeta from 'components/CustomMeta'
import SipAmcCounts from './Components/SipAmcCounts'
import NLVChartReport from './Components/NLVChartReport'
import NiftyCandleChart from './Components/NiftyCandleChart'
import SipAmcCountsClient from './Components/SipAmcCountsClient'
// import CommonChartReport from './Components/CommonChartReport'
import NLVIndividualReport from './Components/NLVIndividualReport'
import UserPerformanceCounts from './Components/UserPerformanceCounts'
import ProfitLossChartReport from './Components/ProfitLossChartReport'
// import NiftyCandleChartRS from './Components/NiftyCandleChartRS'


export default function () {
  const { state: { isClient } } = useContext(AppContext)

  return (
    <div>
      <CustomMeta title='Dashboard' />
      <Row>
        {
          !isClient && <Counts />
        }

        {/* <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <NiftyCandleChartRS />
        </Col> */}

        {
          isClient ?
            <SipAmcCountsClient />
            :
            <SipAmcCounts />
        }

        {!isClient && <UserPerformanceCounts />}

        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <NiftyCandleChart />
        </Col>
        {
          isClient &&
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <NLVChartReport />
          </Col>
        }
        {
          !isClient &&
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <NLVIndividualReport />
          </Col>
        }
        {/* {
          isClient &&
          <Col xl={6} lg={12} md={12} sm={12} xs={24}>
            <CommonChartReport />
          </Col>
        } */}
        {
          !isClient &&
          <Col xl={6} lg={12} md={12} sm={12} xs={24} flex={true} >
            <ProfitLossChartReport />
          </Col>
        }
      </Row>
    </div>
  )
}
