import React from 'react'
import { Table } from 'antd'

const columns = [
  {
    title: 'Interest Report',
    className: 'report-title main-title',
    children: [
      {
        title: 'Mnth',
        dataIndex: 'Mnth',
        key: 'Mnth',
        width: '20%',
        className: 'report-text'
      },
      {
        title: 'Cum Exposure',
        dataIndex: 'CumExposure',
        key: 'CumExposure',
        width: '25%',
        className: 'report-numeric'
      },
      {
        title: 'Cum DPayment',
        dataIndex: 'CumDPayment',
        key: 'CumDPayment',
        width: '15%',
        className: 'report-numeric'
      },
      {
        title: 'TotExposure',
        dataIndex: 'TotExposure',
        key: 'TotExposure',
        width: '20%',
        className: 'report-numeric'
      },
      {
        title: 'Interest',
        dataIndex: 'Interest',
        key: 'Interest',
        width: '20%',
        className: 'report-numeric'
      }
    ]
  }
]

export default function ({ reportData }) {
  const data = reportData.map((report, key) => {
    if ((key + 1) === reportData.length)
      return { key, ...report, Mnth: report.Type }
    return { key, ...report }
  })

  return (
    <Table
      columns={columns}
      dataSource={data}
      className='interest-report'
      pagination={{ pageSize: 1000, hideOnSinglePage: true }}
    // scroll={{ x: true, y: 'calc(100vh - 24px - 24px - 72px - 36px - 35px)' }}
    />
  )
}
