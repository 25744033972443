import gql from 'graphql-tag'

export const GET_ILTS_REPORT = gql`
query spIltsreport($where: SpiltsreportReportInput!){
  spIltsreport(where: $where)
}
`

export const GET_AVERAGE_INDEX_REPORT = gql`
query spAverageIndexreport($where: SpInterestAndspAverageReportInput){
  spAverageIndexreport(where: $where)
}
`

export const GET_INTEREST_REPORT = gql`
query spInterestReport($where: SpInterestAndspAverageReportInput){
  spInterestReport(where: $where)
}
`

export const GET_MONTHLY_RETURN_REPORT = gql`
query spMonthlyReturnReport($where: SpMonthlyReturnReportInput){
  spMonthlyReturnReport(where: $where)
}
`

export const GET_MONTHLY_EXPOSURE_REPORT = gql`
query spMonthlyExposureReport($where: SpMonthlyExposureReportInput){
  spMonthlyExposureReport(where: $where)
}
`

export const GET_PERFORMANCE_REPORT = gql`
query spPerformanceReport($where: SpPerformanceReportInput!){
  spPerformanceReport(where: $where)
}
`

export const GET_NLV_MISMATCH_REPORT = gql`
query spNlvMisMatchReport($where: SpNlvMisMatchReportInput){
  spNlvMisMatchReport(where: $where)
}
`

export const GET_NLV_TRACKER_REPORT = gql`
query spNLVTracker($where: SpNLVTrackerInput){
  spNLVTracker(where: $where)
}
`

export const GET_NLV_INDIVIDUAL_REPORT = gql`
query nlvIndividualReport($groupId: Int){
  nlvIndividualReport(where: { groupId: $groupId })
}
`

export const GET_UNHEDGE_REPORT = gql`
query spUnhedgeReportInput($where: SpUnhedgeReportInput){
  spUnhedgeReportInput(where: $where)
}
`

export const GET_CURRENT_POSITION_REPORT = gql`
query spCurrentPosition{
  spCurrentPosition
}
`

export const GET_TRADER_EQ_REPORT = gql`
query spTradeReportEQ{
  spTradeReportEQ
}
`

export const GET_TRADER_FO_REPORT = gql`
query spTradeReportFO{
  spTradeReportFO
}
`

export const GET_LEDGER_REPORT = gql`
query spLedgerReport{
  spLedgerReport
}
`

export const GET_USER_PERFORMANCE_REPORT = gql`
query spUserPerformanceReport($where: UserPerformanceInput){
  spUserPerformanceReport(where: $where)
}
`
