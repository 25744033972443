import React from 'react'
import { Table } from 'antd'

const columns = [
  {
    title: 'Comparison of Fund with Index Report',
    className: 'report-title main-title',
    children: [
      {
        title: 'ReportDate',
        dataIndex: 'ReportDate',
        key: 'ReportDate',
        className: 'report-text'
      },
      {
        title: 'NIFTY',
        dataIndex: 'NIFTY',
        key: 'NIFTY',
        className: 'report-numeric'
      },
      {
        title: 'NLV',
        dataIndex: 'NLV',
        key: 'NLV',
        className: 'report-numeric'
      },
      {
        title: 'TotalExposure',
        dataIndex: 'TotalExposure',
        key: 'TotalExposure',
        className: 'report-numeric'
      },
      {
        title: 'NetAmt',
        dataIndex: 'NetAmt',
        key: 'NetAmt',
        className: 'report-numeric'
      },
      {
        title: 'Final_NLV',
        dataIndex: 'Final_NLV',
        key: 'Final_NLV',
        className: 'report-numeric'
      },
      {
        title: 'PnlWithOutInterest',
        dataIndex: 'PnlWithOutInterest',
        key: 'PnlWithOutInterest',
        className: 'report-numeric'
      },
      {
        title: 'TotExposureWithNifty',
        dataIndex: 'TotExposureWithNifty',
        key: 'TotExposureWithNifty',
        className: 'report-numeric'
      },
      {
        title: 'Interest',
        dataIndex: 'Interest',
        key: 'Interest',
        className: 'report-numeric'
      },
      {
        title: 'CumInterest',
        dataIndex: 'CumInterest',
        key: 'CumInterest',
        className: 'report-numeric'
      },
      {
        title: 'TotNLV',
        dataIndex: 'TotNLV',
        key: 'TotNLV',
        className: 'report-numeric'
      },
      {
        title: 'NLV%',
        dataIndex: 'NLV%',
        key: 'NLV%',
        width: '4%',
        className: 'report-percentage'
      },
      {
        title: 'TotNLV%',
        dataIndex: 'TotNLV%',
        key: 'TotNLV%',
        className: 'report-percentage'
      }
    ]
  }
]

export default function ({ reportData }) {
  const data = reportData.map((report, key) => { return { key, ...report } })

  return (
    <Table
      dataSource={data}
      columns={columns}
      className='nlv-tracker-report'
      pagination={{ pageSize: 1000, hideOnSinglePage: true }}
    // scroll={{ x: true, y: 'calc(100vh - 24px - 24px - 72px - 36px - 35px)' }}
    />
  )
}
